//
// Define the composition of common primary modules that typically appear on every page.
// --------------------------------------------------
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  .field-name-title & {
    margin-top: 0;
  }
}

h4, .h4 {
  .views-field & {
    margin: 0;
  }
}

ul {
  -webkit-padding-start: 20px;
  &.pagination {
    margin: 0;
    // Views AJAX pager.
    & > li > a {
      .ajax-progress-throbber {
        padding-left: 5px;
      }
    }
  }
}

.navbar-default {
  border: none;
}

.footer {
  border-top: none;
  margin-top: 0;
  padding-bottom: 0;
  padding-top: 0;
}

.btn {
  &:focus, &:active:focus, &.active:focus {
    outline: none;
    outline-offset: 0;
  }
}

.container-inline {
  div.insert-style-select {
    display: inline-block;
  }
}

fieldset.ief-entity-fieldset {
  margin-bottom: 15px;
}

.ief-entity-operations {
  .btn {
    margin: 2px;
  }
}
