//
// Discrete and reusable elements of the UI.
// --------------------------------------------------

@import 'components/form';
// @import 'components/image';
@import 'components/tables';
@import 'components/tabs';
@import 'components/modules';

iframe {
  border: none;
  width: 100%;
}
