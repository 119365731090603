/*
*  Fraction slider animation
*/
.fraction-slider{
	position:relative;
	width:100%; height:100%;
	overflow:visible;
}

.fraction-slider .slide{
	display:none; width:100%; height:100%;
	position:absolute;
	z-index:5000;
}

.fraction-slider .active-slide{
	z-index:9999;
}

.fraction-slider .fs_obj{
	display:block; display:none;
	position:absolute;
	top:0px; left:100%;
	z-index:7000;
}

.fraction-slider .fs_fixed_obj{
	z-index:6000;
	left:0;
}

.fraction-slider .fs_obj *{
	display:inline-block;
	position:relative;
	top:0px; left:0px;
}

.fs_loader{
	width:100%; height:400px;
	background:url(assets/imgs/AjaxLoader.gif) center center no-repeat transparent;
}


