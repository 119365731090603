//
// Form components.
// --------------------------------------------------

.form-control {
  width: auto;
  &.ctools-auto-submit-processed {
    width: 100%;
  }
  &.form-file {
    height: 36px;
    margin-bottom: 2px;
  }
  &.form-wrapper {
    height: auto;
  }
}

.form-email {
  //@include fixins-form-control;
}

.form-group:last-child, .panel:last-child {
  margin-bottom: 15px;
}

.form-managed-file {
  .input-group-btn {
    .btn.form-submit {
      height: 36px;
    }
  }
}

.form-radios {
  padding-left: 25px;
}

.form-required {
  color: $brand-danger;
  font-weight: 700;
}

.form-type-checkbox {
  padding-left: 25px;
  label {
    padding-left: 0;
  }
}
