//
// Drupal modules components.
// --------------------------------------------------

.colorbox {
  &-load:focus, &-inline:focus {
    outline: none;
  }
}

.facebook-comments-box {
  .fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget span iframe[style] {
    width: 100% !important;
  }
}

.flippy {
  li {
    display: inline-block;
  }
}

.geolocation {
  &-address .form-type-textfield {
    clear: left;
    float: none;
  }

  &-help {
    clear: left;
    margin: 0;
  }

  &-client-location {
    white-space: nowrap;
  }

  &-map {
    margin: 0;
  }
}

.quicktabs-tabs {
  padding-left: 0;
}

.shs {
  &-select {
    @include fixins-form-control;
    margin-right: 4px;
  }
  &-term-add-new-wrapper {
    input {
      @include fixins-form-control;
    }
  }
}

ul.shs-hierarchy {
  li:last-of-type:after {
    display: none;
    visibility: hidden;
  }
}

.syntaxhighlighter {
  table {
    tr {
      font-size: 15px !important;
    }
  }
  .toolbar {
    display: none;
  }
}

.ui-dialog .ui-dialog-titlebar {
  @include fixins-border-radius(4px 4px 0 0);
  font-weight: bold;
  background: #e0e0d8;
  border: none;
}

.ui-dialog .ui-dialog-titlebar-close {
  margin: -15px 0 0 0;
  width: 20px;
}

.ui-dialog .ui-dialog-titlebar-close:before {
  position: absolute;
  top: 50%;
  right: 0.2em;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e014";
  margin-top: -6px;
}

.vbo-views-form {
  #edit-select {
    .panel-body {
      display: block;
    }
  }
}

.views-exposed-form {
  .bef-select-as-checkboxes {
    @include fixins-box-shadow(none);
    border: none;
    height: auto;
    width: auto;
  }
  .views-exposed-widget {
    .btn {
      margin-top: 1.5em;
    }
  }
}

.views-table {
  img[alt~="sort"] {
    vertical-align: baseline;
  }
}

.views-slideshow {
  &-cycle-main-frame {
    &, &-row {
      width: 100% !important;
    }
  }

  &-pager-field-item {
    cursor: pointer;
    display: inline-block;
  }
}
