//
// Table components.
// --------------------------------------------------

.table {
  thead, tbody, tfoot {
    tr th, tr td {
      border-top: none;
    }
  }
}

form .field-multiple-table th.field-label {
  padding-left: 8px;
}
