/**
 * Table of Contents
 *
 * 0.0 - Library
 * 1.0 - Reset css theme
 * 2.0 - Component
 * 3.0 - Widget
 * 4.0 - Header
 * 5.0 - Footer
 * 6.0 - Pages
 * 7.0 - Fix ie
 * 8.0 - Responsive
 * 9.0 - Drupal overrides
 */

@import url(https://fonts.googleapis.com/css?family=Titillium+Web:400,300,300italic,400italic,600,700);
@import url(https://fonts.googleapis.com/css?family=Exo+2:300,400,600,700);

$font-heading: 'Titillium Web', sans-serif !default;
$font-normal: 'Exo 2', sans-serif !default;
$color-pri: #0c86c6 !default;
$color-black: #333 !default;
$color-gray: #aaa !default;
$color-grayer: #888 !default;
$color-border: #e5e5e5 !default;

$font-size-base: 15px !default;
$font-size-sm: 12px !default;
$container-large-desktop: 1176px !default;
$brand-primary: $color-pri !default;

%main_color {
  color: $color-pri;
}

%main_bg_color {
  background: $color-pri;
}

%main_bd_color {
  border-color: $color-pri;
}

@mixin search_box($height ){
	height: $height;
	min-width: $height;
	width: $height;
	position: absolute;
	right: 0;
	top: 0;
	&.sb-search-open {
		width: 250px;
	}
	.sb-icon-search, .sb-search-submit {
		height: $height;
		width: $height;
		line-height: $height;
		font-size: 16px;
	}
	.sb-search-input {
		height: $height;
		font-size: 18px;
		color: $color-gray;
		@include placeholder($color-gray);
	}
}

@mixin controls_carousel($height){
	.owl-controls {
		.owl-buttons {
			div {
				height: ($height);
				width: ($height);
				line-height: ($height - 2);
				margin: -($height / 2) 0 0;
				border: 1px solid #555555;
				color: #555;
				font-size: 24px;
				position: absolute;
				top: 50%;
				opacity: 0;
				filter: alpha(opacity=0);
				&:hover {
					@extend %main_bd_color;
					@extend %main_color;
				}
			}
			.owl-prev {
				left: 0;
			}
			.owl-next {
				right: 0;
			}
		}
	}
	&:hover {
		.owl-controls {
			.owl-buttons {
				div {
					opacity: 1;
					filter: alpha(opacity=100);
				}
			}
		}
	}
}

@mixin widget-header(){
	.widget-header {
		text-align: center;
		margin-bottom: 55px;
		.icon {
			display: inline-block;
			width: 32px;
			height: 32px;
			line-height: 32px;
			@extend %main_bg_color;
			color: #fff;
			position: relative;
			border-radius: 50%;
			margin-bottom: 25px;
			&:before,
			&:after {
				content:'';
				height: 1px;
				background: #d7d7d7;
				width: 45px;
				position: absolute;
				top: 50%;
			}
			&:before {
				left: -55px;
			}
			&:after {
				right: -55px;
			}
		}
	}
	.widget-title {
		font-size: 36px;
		font-weight: 400;
		text-transform: uppercase;
		margin-bottom: 8px;
		span {
			@extend %main_color;
		}
	}
	.widget-des {
		font-size: 17px;
		color: $color-gray;
		margin-bottom: 0;
	}
}

@mixin k-widget-desktop() {
	margin: 80px 0;
	&.has-header {
		margin-top: 75px;
		&.has-icon-header {
			margin-top: 80px;
			@include widget-header();
		}
	}
	&.has-background {
		margin: 0;
		.overlay {
			padding: 80px 0;
		}
		&.has-header {
			.overlay {
				padding-top: 75px;
			}
			&.has-icon-header {
				.overlay {
					padding-top: 80px;
				}

			}
		}
	}
}

@mixin item-metadata() {
	text-align: center;
	font-family: $font-heading;
	line-height: 20px;
	overflow: hidden;
	position: absolute;
	left: 0;
	bottom: 0;
	> span {
		width: 70px;
		height: 70px;
		float: left;
		padding-top: 14px;
		> span {
			display: block;
		}
	}
	.item-metadata-time {
		@extend %main_bg_color;
		color: #fff;
	}
	.item-metadata-comments {
		background: #fff;
		color: $color-black;
	}
	.item-metadata-time-day,
	.item-metadata-comments-count {
		font-size: 26px;
		margin-bottom: 5px;

	}
	.item-metadata-time-mon,
	.item-metadata-comments-title {
		font-size: 16px;
	}
}

@mixin format-content() {
	:first-child {
		margin-top:0;
	}
	:last-child {
		margin-bottom: 0;
	}
	p {
		line-height: 24px;
		margin-bottom: 10px;
		color: #818181;
		font-weight: 300;
	}
	ul {
		margin-left: 0;
	}
	img {
		display: block;
		max-width: 100%;
		height: auto;
	}
}

/*
* 0.0 Library
*/
// @import 'inc/bootstrap';
@import 'inc/font-awesome';
@import 'inc/search_bar';
@import 'inc/superfish';
@import 'inc/owl_carousel';
// @import 'inc/woo/woo';
@import 'inc/fractionslider';
@import 'inc/mmenu_all';
@import 'base'; // Drupal overrides and fixins functions.

/*
* 1.0 Format css theme
*/
* {
	outline: none;
}
body {
	font-family: $font-normal;
	font-weight: 300;
	font-size: $font-size-base;
	color: $color-gray;
	overflow-x: hidden;
}
strong {
	color: $color-black;
	font-weight: 600;
}
a {
	@include transition(all .3s);
}
a,a:focus,a:active,a:hover {
	text-decoration: none;
	outline: none;
}
del,
ins {
	font-family: $font-heading;
	font-weight: 400;
	opacity: 1;
	filter: alpha(opacity=100);
}
del {
	text-decoration: line-through;
	color: $color-gray;
	margin-right: 10px;
}
ins {
	font-weight: 700;
	@extend %main_color;
	text-decoration: none;
}
.sf-menu {
	margin-bottom: 0;
	float: left;
}
.divider {
	height: 1px;
	background: #ddd;
}
.form-control {
	@include box-shadow(none);
	border-radius: 0;
	&:focus,
	&:active {
		@include box-shadow(none);
	}
}
.container .container {
	padding: 0;
	width: 100%;
}
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
	font-family: $font-heading;
	color: $color-black;
	a {
		color: $color-black;
		&:hover {
			@extend %main_color;
		}
	}
}
h4 {
	font-size: 18px;
}
textarea {
	resize:none;
}

table {
	background: #fff;
	@extend .table;
	@extend .table-bordered;
}
.btn,input[type="submit"] {
	@include transition(all .3s);
}
.owl-carousel {
	.owl-controls {
		.owl-buttons {
			div {
				padding: 0;
				margin: 0;
				border-radius: 0;
				text-align: center;
				opacity: 1;
				filter: alpha(opacity=100);
				background: none;
				@include transition(all .3s);
			}
		}
	}
}

/*
* 2.0 Component
*/

/* class helper */
.k-font-heading {
	font-family: $font-heading !important;
}
.k-font-normal {
	font-family: $font-normal !important;
}
.k-text-bold {
	font-weight: 700 !important;
}
.k-text-semi-bold {
	font-weight: 600 !important;
}
.k-text-normal {
	font-weight: 400 !important;
}
.k-text-light {
	font-weight: 300 !important;
}
.k-text-pri {
	color: $color-pri !important;
}
.k-text-gray {
	color: $color-gray !important;
}
.k-text-grayer {
	color: $color-grayer !important;
}
.k-text-black {
	color: $color-black !important;
}
.k-text-white {
	color: #fff !important;
}
.k-text-uppercase {
	text-transform: uppercase !important;
}
/* parallax */
.k-parallax {
	min-height: 150px;
	background-position: 50% 0;
	background-repeat: repeat;
	background-attachment: fixed;
}

/* metadata */

.k-item-metadata {
	@include item-metadata();
}

/* accordion, toggle */
.k-accordion,
.k-toggle {
	.panel-default {
		@include box-shadow(none);
		> .panel-heading{
			cursor: pointer;
			font-size: 18px;
			font-family: $font-heading;
			.panel-title {
				line-height: 20px;
			}
		}
	}
	p {
		line-height: 24px;
		color: #818181;
	}
	&.style-1 {
		.panel-default {
			border-color: #ededed;
			> .panel-heading{
				background: #fff;
				padding: 15px 12px;
				border-color: #ededed;
				.fa {
					font-size: 14px;
					margin-right: 8px;
				}
				.panel-title {
					line-height: 20px;
				}
				a {
					@extend %main_color;
					&.collapsed {
						color: $color-black;
						&:hover {
							@extend %main_color;
						}
					}
				}
			}
			> .panel-heading + .panel-collapse > .panel-body {
				border-top-color: #ededed;
			}
			.panel-body {
				line-height: 20px;
				color: $color-grayer;
				padding: 15px 35px;
			}
		}
		.panel + .panel {
			margin-top: 6px;
		}
	}
	&.style-2 {
		.panel-default {
			border: none;
			> .panel-heading{
				background: #fff;
				padding: 0;
				border-bottom:1px solid #dcdcdc;
				.fa {
					font-size: 14px;
					margin-right: 8px;
				}
				a {
					color: $color-black;
					padding: 15px 12px;
					background: #f7f7f7;
					display: block;
					&.collapsed {
						color: $color-black;
						background: #fff;
						padding-left: 0;
						&:hover {
							padding: 15px 12px;
							background: #f7f7f7;
						}
					}
				}
			}
			> .panel-heading + .panel-collapse > .panel-body {
				border: none;
			}
			.panel-body {
				line-height: 20px;
				color: $color-grayer;
				padding: 15px 0;
			}
		}
		.panel + .panel {
			margin-top: 0;
		}
	}
	&.style-3 {
		.panel-default {
			border: none;
			background: none;
			> .panel-heading{
				background: none;
				padding: 0;
				border:none;
				.fa {
					font-size: 14px;
					margin-right: 8px;
				}
				a {
					color: #fff;
					padding: 15px 12px 15px 65px;
					@extend %main_bg_color;
					display: block;
					position: relative;
					.icon {
						display: inline-block;
						height: 40px;
						width: 40px;
						background: url('assets/imgs/icons/minus.png') no-repeat center center;
						position: absolute;
						top: 5px;
						left: 5px;
						border: 1px solid #fff;
					}
					&.collapsed {
						background: rgba(12,134,198,.66);
						.icon {
							background: url('assets/imgs/icons/plus.png') no-repeat center center;
						}
						&:hover {
							@extend %main_bg_color;
						}
					}
				}
			}
			> .panel-heading + .panel-collapse > .panel-body {
				border: none;
			}
			.panel-body {
				line-height: 20px;
				padding: 15px 0;
				p {
					color: #cacaca;
				}
			}
		}
		.panel + .panel {
			margin-top: 10px;
		}
	}
	&.style-4 {
		.panel-default {
			border: none;
			> .panel-heading{
				background: #fff;
				padding: 0;
				border:none;
				.fa {
					font-size: 14px;
					margin-right: 8px;
				}
				a {
					color: $color-black;
					padding: 15px 12px 15px 70px;
					background: #f4f4f4;
					display: block;
					position: relative;
					.icon {
						display: inline-block;
						height: 50px;
						width: 50px;
						background: $color-pri url('assets/imgs/icons/arrow-up.png') no-repeat center center;
						position: absolute;
						top: 0;
						left: 0;
					}
					&.collapsed {
						.icon {
							background: $color-pri url('assets/imgs/icons/arrow-down.png') no-repeat center center;
						}
						&:hover {
						}
					}
				}
			}
			> .panel-heading + .panel-collapse > .panel-body {
				border: none;
			}
			.panel-body {
				line-height: 20px;
				color: $color-grayer;
				padding: 5px 20px 15px;
				background: #f4f4f4;
				margin-left: 50px;
			}
		}
		.panel + .panel {
			margin-top: 4px;
		}
	}
	&.style-5 {
		.panel-default {
			border:2px solid $color-pri;
			border-radius: 0;
			> .panel-heading{
				padding: 0;
				border: none;
				.fa {
					font-size: 14px;
					position: absolute;
					width: 46px;
					height: 46px;
					left: 0;
					top: 0;
					line-height: 46px;
					text-align: center;
				}
				a {
					color: $color-black;
					padding: 13px 12px 13px 50px;
					background: #fff;
					display: block;
					position: relative;
					.fa {
						&:before {
							@extend .fa-minus:before;
						}
					}
					&.collapsed {
						color: $color-black;
						@extend %main_bg_color;
						color: #fff;
						&:hover {
							color: $color-black;
							background: #fff;
						}
						.fa {
							&:before {
								@extend .fa-plus:before;
							}
						}
					}
				}
			}
			> .panel-heading + .panel-collapse > .panel-body {
				border-top: none;
			}
			.panel-body {
				line-height: 20px;
				color: $color-grayer;
				padding: 15px 20px;
			}
		}
		.panel + .panel {
			margin-top: 4px;
		}
	}
}

/* tabs */
.k-tabs {
	.nav-tabs {
		font-family: $font-heading;
		font-size: $font-size-base;
	}
	.tab-content {
		color: #999;
		font-size: $font-size-base;
		line-height: 24px;
		h1,h2,h3,h4,h5,h6 {
			margin-top: 0;
		}
	}
	&.style-1 {
		.nav-tabs {
			border-bottom: none;
			> li {
				border-right: 1px solid #ccc;
				> a {
					margin-right: 0;
					border-radius: 0;
					border: none;
					color: $color-grayer;
					text-transform: uppercase;
					padding: 0 24px;;
					line-height: 24px;
					display: inline-block;
					&:hover {
						color: $color-black;
						background: none;
					}
				}
				&:first-child {
					> a {
						padding-left:0;
					}
				}
				&.active {
					> a:hover, > a:focus, > a{
						color: $color-black;
						background: none;
					}
					> a {
						font-weight: 700;
					}
				}
			}
		}
		.tab-content {
			padding: 20px 0;
		}
	}
	&.style-2 {
		.nav-tabs {
			> li {
				margin-bottom: 0;
				> a {
					display: inline-block;
					line-height: 24px;
					padding: 13px 20px;
					color: $color-black;
					text-transform: uppercase;
					border-radius: 0;
					margin-right: 0;
					border: none;
					border-top: 1px solid #dbdbdb;
					border-right: 1px solid #dbdbdb;
					background: none;
					position: relative;
					&:before {
						content:'';
						width: 100%;
						height: 4px;
						background: #fff;
						position: absolute;
						left: 0;
						bottom: 0;
						@include transition(all .3s);
					}
					&:hover {
						@extend %main_color;
						background: none;
						&:before {
							@extend %main_bg_color;
						}
					}
				}
				&:first-child {
					border-left: 1px solid #dbdbdb;
				}
				&.active {
					> a:hover, > a:focus, > a{
						border-left:none;
						background: none;
					}
					> a {
						@extend %main_color;
						font-weight: 600;
						&:before {
							@extend %main_bg_color;
						}
					}
				}
			}
		}
		.tab-content {
			padding: 30px 25px 25px;
			border: 1px solid #dbdbdb;
			border-top: none;
			h1,h2,h3,h4,h5,h6 {
				margin-top: 0;
			}
		}
	}
	&.style-3 {
		.nav-tabs {
			float: left;
			width: 225px;
			> li {
				float: none;
				display: block;
				margin-top: -1px;
				margin-right: -1px;
				padding-right: 1px;
				position: relative;
				z-index: 1;
				> a {
					display: block;
					border: none;
					border-radius: 0;
					background: #f9fafb;
					padding: 12px 20px;
					margin-right: 0;
					line-height: 24px;
					font-size: 18px;
					color: #a1b1bc;
					position: relative;
					border-bottom: 1px solid #eceeef;
					border-left: 1px solid #eceeef;
					border-top: 1px solid #eceeef;
					&:before {
						content:'';
						background: transparent;
						height: 100%;
						width: 4px;
						position: absolute;
						left: -1px;
						top: 0;
						@include transition(all .3s);
					}
					&:hover {
						background: #fff;
						@extend %main_color;
						border-left-color: #c0ccd3;
						border-bottom-color: #c0ccd3;
						border-top-color: #c0ccd3;
						&:before {
							@extend %main_bg_color;
						}
					}
					.fa {
						margin-right: 10px;
					}
				}
				&:first-child {
					margin-top: 0;
				}
				&.active {
					z-index: 2;
					padding-right: 0;
					> a:hover, > a:focus, > a{
						background:#fff;

					}
					> a {
						@extend %main_color;
						border-left-color: #c0ccd3;
						border-bottom-color: #c0ccd3;
						border-top-color: #c0ccd3;
						&:before {
							@extend %main_bg_color;

						}
					}
				}
				&:hover {
					z-index: 2;
					padding-right: 0;
				}
			}
		}
		.tab-content {
			overflow: hidden;
			border: 1px solid #c0ccd3;
			padding: 20px;
		}

		> .panel-group {
			.panel {
				@include box-shadow(none);
				border-radius: 0;
			}
			.panel-heading {
				padding: 0;
				a {
					@extend %main_color;
					display: block;
					background: #f9fafb;
					line-height: 30px;
					padding: 10px 20px;
					background: #fff;
					position: relative;
					&:before {
						content:'';
						width: 5px;
						height: 100%;
						@extend %main_bg_color;
						left: -1px;
						top: -1px;
						position: absolute;
					}
					&.collapsed {
						background: #f9fafb;
						color: #a1b1bc;
						&:before {
							display: none;
						}
					}
					.fa {
						margin-right: 15px;
					}
				}
				/*c0ccd3*/
			}
			.panel-title {
				font-size: 18px;
			}
			.panel + .panel {
				margin-top: -1px;
			}
			.panel-body {
				padding: 15px 25px 30px;
				background: #fff;
				border-top: none;
			}

		}
	}
}

/* progress bar */
.k-progress {
	margin-bottom: 23px;
	.progress-title {
		font-size: 18px;
		margin-top: 0;
	}
	.progress {
		height: 10px;
		border: 1px solid #ccc;
		padding: 3px;
		position: relative;
		overflow: visible;
		margin-bottom: 0;
		background: #fff;
		@include box-shadow(none);
		@extend .clearfix;
	}
	.progress-bar {
		height: 2px;
		background: $color-black;
		@include box-shadow(none);
	}
	span {
		position: absolute;
		top: -25px;
		right: 0;
		font-family: $font-heading;
		line-height: 1;
		display: inline-block;
		color: $color-black;
		font-size: $font-size-base;
	}
}

/* blockquote */
.k-blockquote {
	border-left: 4px solid #e3e3e3;
	font-size: 18px;
	font-family: $font-heading;
	color: $color-black;
	padding: 17px 20px;
	&.style-2 {
		border: 1px solid #e3e3e3;
		border-left:4px solid $color-pri;
		position: relative;
		&:before {
			@extend .fa;
			@extend .fa-quote-right:before;
			position: absolute;
			z-index: -1;
			font-size: 60px;
			right: 15px;
			bottom: 10px;
			color: #e0e0e0;
		}
	}
	&.style-1,
	&.style-2 {
		small {
			font-size: 16px;
			color: #777;
			padding-left: 32px;
			position: relative;
			&:before {
				content:'';
				height: 1px;
				width: 22px;
				background: $color-grayer;
				position: absolute;
				left: 0;
				top: 50%;
			}
		}
		p {
			line-height: 24px;
		}
	}
	p {
		line-height: 24px;
	}
	&.style-3 {
		border: 1px solid #eeeeee;
		border-left:6px solid $color-pri;
		background: #fff;
		position: relative;
		padding: 20px 25px 10px 90px;
		font-family: $font-heading;
		margin: 20px 0;
		&:before {
			@extend .fa;
			@extend .fa-quote-left:before;
			position: absolute;
			font-size: 45px;
			left: 25px;
			top: 25px;
			color: #ddd;
		}
		p {
			font-size: 16px;
			font-weight: 300;
			font-style: italic;
		}
		small{
			font-size: 18px;
			@extend %main_color;
			font-style: normal;
			font-weight: 400;
			margin-top: 3px;
			display: inline-block;
			&:before {
				content:'';
			}

		}
	}

}

/* dropcap */
.k-dropcap{
    text-transform:uppercase;
    color: $color-black;
    float:left;
    margin-right:10px;
    text-align:center;
    display:inline-block;
    font-weight: 700;
    font-family: $font-heading;
    font-size: 36px;
    line-height: 40px;
    &.style-2 {
    	@extend %main_bg_color;
    	color: #fff;
    	width: 46px;
    	height: 54px;
    	line-height: 54px;
    }
    &.style-3 {
    	background: #808080;
    	color: #fff;
    	width: 46px;
    	height: 54px;
    	line-height: 54px;
    }
}

/* read more */
.read-more {
	font-size: $font-size-sm;
	font-family: $font-heading;
	text-transform: uppercase;
	font-weight: 700;
	display: inline-block;
	color: $color-black;
	letter-spacing: 2px;
	&:hover {
		@extend %main_color;
	}
	&.read-more-border {
		line-height: 44px;
		border: 1px solid #dedede;
		padding: 0 20px;
		&:hover {
			@extend %main_bd_color;
		}
	}
	&.read-more-arrow {
		&:after {
			@extend .fa;
			@extend .fa-angle-right:before;
			margin-left: 5px;
			font-size: 14px;
		}
	}
}

/* icon box */
.icon-box {
	h3 {
		font-weight: 400;
		font-size: 20px;
	}
	p {
		color: #818181;
		line-height: 24px;
		font-size: $font-size-base;
	}
	&.style-1 {
		margin-bottom: 30px;
		.icon {
			height: 68px;
			width: 68px;
			line-height: 68px;
			color: #fff;
			@extend %main_bg_color;
			font-size: 32px;
			text-align: center;
			margin-bottom: 25px;
			position: relative;
			&:after {
				pointer-events: none;
				position: absolute;
				width: 100%;
				height: 100%;
				content: '';
				-webkit-box-sizing: content-box;
				-moz-box-sizing: content-box;
				box-sizing: content-box;
				top: -3px;
				left: -3px;
				padding: 3px;
				box-shadow: 0 0 0 3px $color-pri;
				-webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
				-webkit-transform: scale(.8);
				-moz-transition: -moz-transform 0.2s, opacity 0.2s;

				transition: transform 0.2s, opacity 0.2s;
				-webkit-transform: scale(1.2);
				-moz-transform: scale(1.2);
				-ms-transform: scale(1.2);
				transform: scale(1.2);
				opacity: 0;
			}
			&:hover {
				&:after {
					-webkit-transform: scale(1);
					-moz-transform: scale(1);
					-ms-transform: scale(1);
					transform: scale(1);
					opacity: 1;
				}
			}
		}
		h3 {
			font-size: 24px;
			margin-top: 0;
			margin-bottom: 15px;
		}
		p {
			font-size: $font-size-base;
			margin-bottom: 25px;
			color: #818181;
		}
	}
	&.style-2 {
		text-align: center;
		.icon {
			height: 70px;
			width: 70px;
			border-radius: 50%;
			line-height: 68px;
			text-align: center;
			font-size: 26px;
			margin-bottom: 25px;
			color: $color-gray;
			border: 1px solid #d6d9dc;
			@include transition(all .3s);
			&.active {
				@extend %main_bd_color;
				@extend %main_color;
			}
		}
		.divider {
			width: 30px;
			display: inline-block;
			margin: 20px 0 10px;
		}
		h3 {
			margin: 0;
		}
		p {
			margin-bottom: 25px;
		}
		&:hover {
			.icon {
				@extend %main_bd_color;
				@extend %main_color;
			}
		}
	}
	&.style-3 {
		@include transition(all .3s);
		border: 1px solid #ececec;
		padding: 60px 20px 35px;
		text-align: center;
		.icon {
			font-size: 40px;
			margin-bottom: 25px;
			color: #ddd;
			@include transition(all .3s);
		}
		h3 {
			margin: 0 0 15px;
		}
		p {
			margin-bottom: 20px;
		}
		&:hover {
			@extend %main_bd_color;
			.icon {
				@extend %main_color;
			}
		}
	}
	&.style-4 {
		padding-left: 68px;
		position: relative;
		margin-bottom: 60px;
		.icon {
			position: absolute;
			left: 0;
			top: 0;
			font-size: 32px;
			@extend %main_color;
		}
		h3 {
			margin: 0 0 20px;
		}
		p {
			margin-bottom: 0;
		}
	}
	&.style-5 {
		position: relative;
		margin-bottom: 55px;
		h3 {
			margin: 0 0 12px;
		}
		p {
			margin-bottom: 0;
		}
		.icon {
			height: 75px;
			width: 75px;
			line-height: 73px;
			text-align: center;
			border: 1px solid #ddd;
			color: $color-black;
			font-size: 28px;
			position: absolute;
			top: 0;
			@include transition(all .3s);
		}
		&.icon-box-right {
			padding-right: 95px;
			text-align: right;
			.icon {
				right: 0;
			}
		}
		&.icon-box-left {
			padding-left: 95px;
			text-align: left;
			.icon {
				left: 0;
			}
		}
		&:hover {
			.icon {
				@extend %main_bd_color;
				@extend %main_color;
			}
		}
	}

}

/* pricing table*/
.k-pricing-table {
	text-align: center;
	font-size: $font-size-base;
	color: #555;
	background: #fff;
	@extend .list-unstyled;
	.title, .button {
		font-family: $font-heading;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 2px;
	}
	.button {
		display: inline-block;
	}
	&.style-1 {
		.title {
			color: #fff;
			@extend %main_bg_color;
			display: block;
			line-height: 24px;
			padding: 13px 0;
		}
		.price {
			font-size: 70px;
			color: $color-black;
			padding-top: 8px;
			span {
				font-size: 36px;
			}
		}
		.time-unit {
			color: #c0c0c0;
			text-transform: uppercase;
			padding-bottom: 25px;
			margin-top: -8px;
		}
		.bullet-item {
			line-height: 30px;
			padding: 20px 0;
			border-top: 1px solid #e7e7e7;
			.span {
				font-weight: 600;
			}
		}
		.button {
			font-size: $font-size-sm;
			text-transform: uppercase;
			color: $color-black;

		}
		.cta-button {
			.button {
				display: block;
				background: #e8e8e8;
				line-height: 24px;
				padding: 13px 0;
				&:hover {
					background: $color-black;
					color: #fff;
				}
			}
		}

		&.popular {
			.title{
				background: $color-black;
			}
			.button {
				color: #fff;
				background: $color-black;
			}
		}

	}
	&.style-2 {
		.title,
		.time-unit {
			text-transform: uppercase;
			color: #fff;
			@extend %main_bg_color;
		}
		.title {
			font-size: 18px;
			padding-top: 40px;
			padding-bottom: 7px;
		}
		.time-unit {
			font-size: $font-size-sm;
			padding-bottom: 30px;
			color: #bce1f4;
		}

		.price {
			font-size: 40px;
			line-height: 50px;
			color: $color-black;
			padding: 40px 0;
		}
		.bullet-item {
			line-height: 24px;
			padding: 13px 0;
			&.even {
				background: #f2f2f2;

			}
		}
		.cta-button {
			padding: 30px 0 50px;
		}
		.button {
			line-height: 22px;
			padding: 12px 16px;
			border: 1px solid #dedede;
			font-family: $font-normal;
			font-weight: 300;
			font-size: $font-size-sm;
			color: $color-black;
			&:hover {
				@extend %main_bd_color;
				@extend %main_color;
			}
		}
		&.popular {
			.title,
			.time-unit {
				background: #4ec2ff;
			}
			.button {
				@extend %main_bd_color;
				@extend %main_color;
			}
		}

	}
}

/* socials */
.list-social-2 {
	overflow: hidden;
	margin-top: 20px;
	a {
		float: left;
		margin-right: 10px;
		margin-bottom: 10px;
		height: 40px;
		width: 40px;
		line-height: 40px;
		text-align: center;
		color: #fff;
		border-bottom: none !important;
		&:hover {
			opacity: .8;
			filter: alpha(opacity=80);
		}
		&.fa-facebook {
			background: #3b5998;
		}
		&.fa-twitter {
			background: #00aced;
		}
		&.fa-rss {
			background: #ed7000;
		}
		&.fa-google-plus {
			background: #2c3e50;
		}
		&.fa-dribbble {
			background: #eb5d8c;
		}

	}
}

/* pagination */
.k-pagination {
	margin: 45px 0 70px;
	position: relative;
	z-index: 99;
	@extend .clearfix;
	.read-more.read-more-border {
		@extend %main_bg_color;
		@extend %main_bd_color;
		color: #fff;
		padding: 0 30px;
	}

	ul.page-numbers  {
		float: right;
	}
}

ul.page-numbers {
	font-family: $font-heading;
	overflow: hidden;
	display: inline-block;
	margin: 0 -6px;
	@extend .list-unstyled;
	li {
		float: left;
	}
	a {
		color: $color-black;
		&:hover {
			@extend %main_color;
		}
	}
	.page-numbers {
		width: 30px;
		height: 30px;
		display: inline-block;
		line-height: 30px;
		text-align: center;
		margin: 0 6px;
	}
	.prev,.next {
		font-weight: 600;
		color: $color-black;
		text-transform: uppercase;
		line-height: 30px;
		width: auto;
		i {
			font-size: 16px;
		}
	}
	.prev {
		 i {
		 	margin-right: 6px;
		 }
	}
	.next {
		i {
			margin-left: 6px;
		}
	}
	.current {
		@extend %main_bg_color;
		color: #fff;
		border-radius: 50%;
	}
}

/* Alignments */
.alignleft {
	display: inline;
	float: left;
}

.alignright {
	display: inline;
	float: right;
}

.aligncenter {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

blockquote.alignleft,
.wp-caption.alignleft,
img.alignleft {
	margin: 0.4em 1.6em 1.6em 0;
}

blockquote.alignright,
.wp-caption.alignright,
img.alignright {
	margin: 0.4em 0 1.6em 1.6em;
}

blockquote.aligncenter,
.wp-caption.aligncenter,
img.aligncenter {
	clear: both;
	margin-top: 0.4em;
	margin-bottom: 1.6em;
}

.wp-caption.alignleft,
.wp-caption.alignright,
.wp-caption.aligncenter {
	margin-bottom: 1.2em;
}

/* widget */

.widget {
	.widget-title {
		margin-top: 0;
		font-weight: 400;
	}
	.item-title {
		font-weight: 400;
	}
	.panel-group {
		margin-bottom: 0;
	}
}
.k-widget-demo {
	.widget-title {
		border-bottom: 1px solid $color-border;
		padding-bottom: 15px;
		margin-top: 0;
		margin-bottom: 40px;
		font-size: 20px;
		font-weight: 700;
		text-transform: uppercase;
	}
}
.main-section {
	overflow: hidden;
}

.sidebar {
	.widget {
		margin-bottom: 40px;
	}
	.widget-title {
		font-size: 24px;
		margin-bottom: 26px;
	}
	.widget_text,
	.widget_archive,
	.widget_categories,
	.widget_links,
	.widget_meta,
	.widget_nav_menu,
	.widget_pages,
	.widget_recent_comments,
	.widget_recent_entries {
		.widget-title {
			margin-bottom: 21px;
		}
	}
	.k-widget-accordion {
		.k-accordion,
		.k-toogle {
			&.style-3 {
				.panel-default {
					> .panel-heading {
						a {
							border-bottom: none;
							background: none;
							color: #2c3e50;
							font-size: 18px;
							padding-left: 35px;

							&:hover {
								background: none;
							}
							.icon {
								background: #e2e2e2;
								height: 20px;
								width: 20px;
								text-align: center;
								line-height: 14px;
								color: #fff;
								top: 15px;
								left: 0;
								&:before {
									@extend .fa;
									@extend .fa-minus:before;
									font-size: 10px;
									margin-right: 0;
								}
							}
							&.collapsed {
								border-bottom: 1px solid $color-border;
								.icon {
									@extend %main_bg_color;
									&:before {
										@extend .fa-plus:before;
									}
								}
							}
						}
					}
					&:first-child {
						> .panel-heading {
							padding-top: 5px;
							a {
								padding-top: 0;
								.icon {
									top: 0;
								}
							}
						}
					}
				}
				.panel + .panel {
					margin-top: 0;
				}
				.collapse.in {
					border-bottom: 1px solid $color-border;
				}
				.panel-body {
					padding: 0 0 10px;
					line-height: 24px;
					color: #818181;
					:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
	.k-post-sm-thumb {
		.item-title {
			font-size: 16px;
		}
	}
}
.k-widget_search {

}
.woocommerce {
	.widget_products,
	.widget_recently_viewed_products,
	.widget_top_rated_products {
		.product_list_widget {
			padding: 0;
			@extend .list-unstyled;
			> li {
				padding: 20px 0;
				border-top: 1px solid #dedede;
				overflow: hidden;
				&:first-child {
					padding-top:0;
					border-top: none;
				}
				&:last-child {
					padding-bottom: 0;
				}
				img {
					float: left;
					width: 75px;
					height: 75px;
					display: block;
					margin-right: 20px;
					margin-left: 0;
				}
				.product-title {
					font-family: $font-heading;
					font-size: 17px;
					line-height: 24px;
					font-weight: 400;
					margin-bottom: 10px;
					display: block;
					overflow: hidden;
					color: $color-black;
					&:hover {
						@extend %main_color;
					}
				}
			}
		}
	}

	.widget_price_filter {
		.price_slider_wrapper {
			.ui-slider-horizontal {
				height: 8px;
				border-radius: 15px;
				background: $color-black;
				border: none;
				margin: 0 8px 15px;
				position: relative;
			}
			.ui-slider .ui-slider-range,
			.ui-slider .ui-slider-handle {
				@extend %main_bg_color;
				position: absolute;
				height: 100%;
				top: 0;
				left: 8px;
				border-radius: 15px;
			}
			.ui-slider .ui-slider-handle {
				@extend %main_bg_color;
			    position: absolute;
			    z-index: 2;
			    width: 16px;
			    height: 16px;
			    border-radius: 15px;
			    cursor: ew-resize;
			    outline: 0;
			    top: -4px;
			    margin-left: -8px;
			}
			button[type="submit"] {
				@extend .btn;
				@extend .btn-primary;
				float: left;
				font-size: 12px;
				font-family: $font-heading;
				font-weight: 700;
				text-transform: uppercase;
			}
			.price_label {
				text-align: right;
				line-height: 30px;
			}
		}

	}
}

.k-widget_search {
	form {
		position: relative;
	}
	.form-control {
		background: #f6f6f6;
		height: 52px;
		border-color: #e0e0e0;
		color: $color-gray;
		@include placeholder($color-gray);
		padding-left: 44px;
	}
	button {
		position: absolute;
		height: 50px;
		width: 44px;
		line-height: 50px;
		left: 0;
		top: 0;
		text-align: center;
		padding: 0;
		background: none;
		border: none;
		font-size: 18px;
		color: $color-gray;
	}
}

/*
* Widget default
 */
.widget_calendar table {
	margin: 0;
}

.widget_calendar td,
.widget_calendar th {
	line-height: 2.3333;
	text-align: center;
	padding: 0;
}

.widget_calendar caption {
	font-weight: 700;
	margin: 0 0 1.6em;
	letter-spacing: 0.04em;
	text-transform: uppercase;
}

.widget_calendar tbody a {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0.3);
	background-color: #333;
	color: #fff;
	display: block;
	font-weight: 700;
}

.widget_calendar tbody a:hover,
.widget_calendar tbody a:focus {
	background-color: $color-border;
	color: #fff;
}

.widget_archive,
.widget_categories,
.widget_links,
.widget_meta,
.widget_nav_menu,
.widget_pages,
.widget_recent_comments,
.widget_recent_entries,
.widget_product_categories,
.k-widget-recent-comments {
	font-family: $font-heading;
	a {
		border: 0;
		color: #818181;
		&:hover {
			@extend %main_color;
			padding-left: 15px;
		}
	}
	ul {
		@extend .list-unstyled;
		margin: 0;
	}
	> ul {
		> li {
			&:last-child {
				padding-bottom: 13px;
				border-bottom: 1px solid $color-border;
			}
		}
	}
	li {
		border-top: 1px solid $color-border;
		padding: 15px 0 10px;
		line-height: 24px;
		&:first-child {
			border-top: 0;
			padding-top: 0;
		}
		&:last-child {
			padding-bottom: 0;
		}
	}

	.children,
	.sub-menu {
		border-top: 1px solid $color-border;
		margin: 13px 0 0 0;
		padding-top: 13px;
	}
}
.k-widget-recent-comments a:hover{
	padding-left: 0;
}

.widget_archive,
.widget_categories,
.widget_links,
.widget_meta,
.widget_nav_menu,
.widget_pages,
.widget_recent_entries,
.widget_product_categories {
	a {
		display: block;
		float: left;
	}
	li {
		overflow: hidden;
		text-align: right;
	}
}

.k-widget-recent-comments {
	img {
		float: left;
		width: 46px;
		height: auto;
		display: block;
		margin-right: 12px;
		margin-bottom: 20px;
	}
	a {
		font-family: $font-heading;
		display: block;
		overflow: hidden;
	}
	time {
		font-size: 13px;
		@extend %main_color;
		display: block;
		margin-top: -5px;
	}
	.item-author {
		color: $color-black;
		font-weight: 500;
		font-style: italic;
		span {
			color: $color-grayer;

		}
	}
	p {
		margin-bottom: 0;
		color: #818181;
		font-family: $font-normal;
		font-weight: 300;
	}
}
.widget_recent_entries .post-date {
	display: block;
}

.widget_rss ul {
	list-style: none;
	margin: 0;
}

.widget_rss li {
	margin-bottom: 1.6em;
}

.widget_rss ul:last-child,
.widget_rss li:last-child {
	margin-bottom: 0;
}

.widget_rss .rsswidget {
	border: 0;
	font-weight: 700;
}

.widget_rss .rsswidget img {
	margin-top: -4px;
}

.widget_rss .rss-date,
.widget_rss cite {
	font-size: $font-size-sm;
	font-style: normal;
	display: block;
	line-height: 2;
	opacity: 0.8;
}

.textwidget > :last-child {
	margin-bottom: 0;
}

.textwidget a {
	border-bottom: 1px solid #333;
}

.textwidget a:hover,
.textwidget a:focus {
	border-bottom: 0;
}

.widget_tag_cloud {
	.tagcloud {
		overflow: hidden;
		a {
			display: inline-block;
			float: left;
			margin-right: 6px;
			margin-bottom: 6px;
			line-height: 22px;
			padding: 10px 30px;
			border: 1px solid #d6d9dc;
			color: #818181;
			font-family: $font-heading;
			background: #fff;
			&:hover {
				@extend %main_bd_color;
				@extend %main_color;
			}
		}
	}
}

/* end widget default*/

.item-thumb {
	> a {
		display: block;
	}
	img {
		display: block;
		width: 100%;
		height: auto;
	}
}

.k-widget {
	@include k-widget-desktop();
}
.k-widget-logo {
	height: 150px;
	background: url(assets/imgs/bg-slider.png) no-repeat center top;
	.overlay {
		background: #ddd;
		background: rgba(0,0,0,.9);
	}
	.owl-carousel {
		.owl-controls {
			margin: 0;
			.owl-buttons {
				div {
					width: 30px;
					height: 30px;
					border: 2px solid #c6c6c6;
					line-height: 26px;
					position: absolute;
					top: 50%;
					margin: -15px 0 0;
					border-radius: 50%;
					&.owl-prev {
						left: -70px;
					}
					&.owl-next {
						right: -70px;
					}
				}
			}
		}
	}
	.item {
		line-height: 150px;
		text-align: center;
	}
}

.k-widget-testi {
	background-image: url(assets/imgs/bg-slider-1.png);
	min-height: 200px;
	color: #fff;
	text-align: center;
	.widget-title {
		font-size: 30px;
		color: #fff;
		margin-bottom: 55px;
	}
	.overlay {
		@extend %main_bg_color;
		background: rgba(12,134,198,.9);
		padding: 50px 0;
	}
	.item {
		font-family: $font-heading;
		padding: 0 10%;
	}
	.icon {
		height: 68px;
		width: 68px;
		line-height: 68px;
		@extend %main_color;
		font-size: 20px;
		margin-bottom: 25px;
		background: #fff;
		border-radius: 50%;
	}
	p {
		line-height: 30px;
		font-size: 20px;
		margin-bottom: 15px;
	}
	strong {
		font-weight: 700;
		color: #fff;
	}
	.owl-theme .owl-controls {
		margin-top: 25px;
		.owl-page {
			margin: 0 7px;
			span {
				height: 8px;
				width: 8px;
				background: #fff;
				border-radius: 50%;
				opacity: 1;
				filter: alpha(opacity=100);
				position: relative;
				margin: 0;
				&:before {
					content:'';
					height: 20px;
					width: 20px;
					border: 2px solid transparent;
					border-radius: 50%;
					position: absolute;
					left: -6px;
					top: -6px;
					@include transition(all .3s);
				}
				&:hover {
					&:before {
						border-color:#fff;
					}
				}

			}
			&.active {
				span {
					&:before {
						border-color:#fff;
					}
				}
			}
		}
	}
	&.style-2 {
		background: url(assets/imgs/bg-testi-2.png) no-repeat center top;
		.overlay {
			background: #3f3f3f;
			background: rgba(0,0,0,.7);
			overflow: hidden;
			padding: 55px 0 105px;
		}
		.widget-content {
			position: relative;
			background: rgba(12,134,198,.6);
			padding: 52px 0 32px;
			&:before,
			&:after {
				content:'';
				position: absolute;
				top: 0;
				background: rgba(12,134,198,.6);
				width: 50%;
				height: 100%;
			}
			&:before {
				right: 100%;
			}
			&:after {
				left: 100%;
			}
		}
		.icon {
			height: 46px;
			width: 46px;
			line-height: 46px;
			border: 2px solid #fff;
			@extend %main_bg_color;
			position: absolute;
			top: -23px;
			left: 50%;
			margin-left: -23px;
			margin-bottom: 0;
			color: #fff;
			font-size: 16px;
		}
		p {
			font-size: 18px;
			line-height: 24px;
			margin-bottom: 15px;
		}
		strong {
			font-weight: 400;
			color: #a1dfff;
		}
		.owl-controls {
			margin: 0;
			position: absolute;
			left: 0;
			bottom: -80px;
			width: 100%;
			height: 20px;
			.owl-page {
				span {
					background: $color-grayer;
					&:before {
						display:none;
					}
					&:hover {
						background: #fff;
					}
				}
				&.active {
					span {
						background: #fff;
					}
				}
			}
		}
	}
}

.k-widget-text {
	background-color: #f8f8f8;
	text-align: center;
	padding: 60px 0;
	.widget-title {
		font-size: 36px;
		font-weight: 300;
		margin-bottom: 18px;
	}
	.widget-content {
		color: $color-gray;
	}
	&.bg-img {
		padding: 0;
		background-image: url(assets/imgs/bg-text.png);
		.overlay {
			@extend %main_bg_color;
			background: rgba(12,134,198,.95);
			padding: 45px 0 50px;
		}
		.widget-title {
			font-size: 30px;
			font-weight: 400;
			color: #fff;
			margin-bottom: 22px;
		}
		.read-more {
			border-color: #fff;
			color: #fff;
			margin: 0 6px;
			&:hover {
				border-color: #fff;
				background: #fff;
				@extend %main_color;
			}
		}
		&.has-des {
			background: url(assets/imgs/bg-widget-text.png) no-repeat center top;
			.overlay {
				@extend %main_bg_color;
				background: rgba(12,134,198,.8);
				padding: 120px 0 130px;
			}
			.widget-title {
				font-size: 44px;
				margin-bottom: 17px;
			}
			.widget-des {
				font-size: 22px;
				color: #d9d9d9;
				margin-top: 0;
				margin-bottom: 43px;
			}
			.read-more.read-more-border {
				padding: 5px 35px
			}
		}
	}

}

.k-sale {
	@extend %main_bg_color;
	color: #fff;
	font-size: 18px  ;
	font-family: $font-heading;
	line-height: 30px  ;
	position: absolute;
	right: -45px ;
	left: auto ;
	top: 25px ;
	padding: 0 70px  ;
	transform:rotate(45deg);
	border-radius: 0  ;
	min-height: auto ;
	min-width: auto ;
	font-weight: 400;
	z-index: 9;
}

.k-product-buttons {
	overflow: hidden;
	a {
		float: left;
		margin-right: 1px;
		@extend %main_bg_color;
		color: #fff;
		height: 60px;
		width: 60px;
		display: inline-block;
		line-height: 60px;
		text-align: center;
		font-size: 16px;
		&:hover,&.active {
			background: #dadada;
		}
	}
}

.k-feature-products {
	.item-thumb {
		overflow: hidden;
		position: relative;
	}

	.item-title {
		font-size: 24px;
		font-weight: 400;
	}
	p.item-price {
		font-family: $font-heading;
		margin-bottom: 22px;
		.item-price {
			font-weight: 700;
			@extend %main_color;
			margin-top: 15px;
		}
		.item-price-original {
			text-decoration: line-through;
			color: $color-gray;
			margin-right: 10px;
		}
	}

	.k-product-buttons {
		a {
			background: #dadada;
			&:hover,&.active {
				@extend %main_bg_color;
			}
		}
	}
}

.k-widget-portfolio {
	.widget-content {
		overflow: hidden;
	}
	.hover_div {
		margin: 0 -5px 30px;
		@extend .list-unstyled;
		a {
			color: #c7dfeb;
			display: block;
			position: relative;
			overflow: hidden;
			img {
				@include transition(all 0.3s);
				@include scale(1.1);
			}
			> div {
				position: absolute;
				background: $color-black;
				background: rgba(0,0,0,0.58);
				width: 100%;
				height: 100%;
				vertical-align: middle;
				top: 0;
				left: 0;
				opacity: 0;
				filter: alpha(opacity=0);
				@include scale(1.3);
				@include transition(all 0.3s);

			}
			&:hover {
				img {
					@include scale(1);
				}
				> div {
					@include scale(1);
					opacity: 1;
					filter: alpha(opacity=100);
				}
			}
		}
	}
	.inner {
		position: absolute;
		width: 100%;
		min-height: 51px;
		left: 0;
		top: 50%;
		margin-top: -25px;
	}

	.item {
		padding: 5px;
		width: 25%;
		text-align: center;
		font-size: 18px;
		font-family: $font-heading;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
		&.item-sm {
			width: 25%;
		}
		&.item-lg {
			width: 50%;
		}
	}
	.item-title {
		font-size: 18px;
		margin-top: 0;
		color: #fff;
		text-transform: uppercase;
		font-weight: 700;
		margin-bottom: 7px;
	}
	p {
		color: #fff;
		margin-bottom: 0;
	}
}

.k-widget-post-two-size-thumb {
	background: #f4f4f4 url('assets/imgs/bg-post.png') no-repeat center top;
	.container {
		position: relative;
	}
	.item-thumb {
		position: relative;
		margin-bottom: 25px;
		overflow: hidden;
		img {
			@include transition(all .3s);
			&:hover {
			 @include scale(1.2);
			}
		}

	}
	.item-title {
		font-size: 24px;
		margin-bottom: 15px;
	}
	.item {
		color: $color-grayer;
		p {
			margin-bottom: 0;
		}
	}
	p.text-center {
		height: 46px;
		position: absolute;
		bottom: 0;
		right: 0;
		border-color: #cccccc;
		display: inline-block;
	}
	.item-sm {
		.item-title {
			font-size: 21px;
		}
	}
}

.widget-area-2, .widget-area-3 {
	padding-top: 73px;
	padding-bottom: 55px;
	.widget {
		margin-bottom: 20px;
	}
}
.widget-area-2 {
	color: #ccc;
	.widget-title {
		font-size: 22px;
		color: #fff;
		margin-bottom: 28px;
	}
	.widget_text .textwidget{
		font-size: 18px;
		small {
			font-size: $font-size-base;
			color: #777;
		}
		p:last-child {
			margin-bottom: 0;
		}
	}
}

.k-widget-newsletter {
	.widget-des {
		margin-top: 2px;
		margin-bottom: 10px;
	}
	.widget-title {
		margin-bottom: 37px;
	}
	form {
		position: relative;
		margin-bottom: 30px;

	}
	input {
		height: 60px;
		border: none;
	}
	input[type="text"] {
		background: #32363e;
		color: $color-border;
		@include placeholder($color-border);
	}
	input[type="submit"] {
		position: absolute;
		right: 0;
		top: 0;
		padding: 0 30px;
		line-height: 60px;
		color: #fff;
		@extend %main_bg_color;
		font-family: $font-heading;
		font-weight: 700;
		font-size: 13px;
		border-left: 3px solid #252932;
		text-transform: uppercase;
		border-radius: 0;
		letter-spacing: 2px;
		&:hover {
			background: #fff;
			@extend %main_color;
		}
	}
	.form-info {
		margin-bottom: 0;
	}
}

.k-widget-newsletter-2 {
	background: url(assets/imgs/bg-newsletter.png) no-repeat center top;
	text-align: center;
	.overlay {
		background: $color-black;
		background: rgba(0,0,0,.78);
	}
	.widget-des {
		color: #c1c1c1;
		font-size: $font-size-base;
		font-weight: 600;
		text-transform: uppercase;
		margin: 0 0 15px;
	}
	.widget-title {
		font-size: 36px;
		color: #fff;
		margin-bottom: 22px;
		text-transform: uppercase;
	}
	.widget-des-2 {
		color: $color-gray;
		margin-bottom: 45px;
	}
	form {
		position: relative;
	}
	.form-control {
		border: 2px solid $color-pri;
		padding-right: 80px;
		color: $color-grayer;
		height: 60px;
		background: none;
		@include placeholder($color-border);
	}
	button {
		@extend %main_color;
		font-size: 26px;
		position: absolute;
		top: 15px;
		right: 15px;
		background: none;
		border: none;
	}
}

.widget-area-3 {
	background: #252932;
	position: relative;
	padding-left: 30px;
	.widget-title {
		color: #fff;
		font-size: 24px;
	}
	.k-widget-newsletter {
		.widget-des {
			font-size: 18px;
			font-weight: 400;
			color: #ccc;
		}
		.form-info {
			color: #777;
		}
	}
}

.k-post-sm-thumb {
	.list-item {
		margin-bottom: 0;
	}
	.item {
		overflow: hidden;
		padding: 15px 0;
		border-top: 1px solid #dedede;
		&:first-child {
			padding-top:0;
			border-top: none;
		}
		&:last-child {
			padding-bottom: 0;
		}
	}
	.item-thumb {
		float: left;
		width: 70px;
		margin-right: 20px;
	}
	.item-title {
		font-size: 18px;
		font-weight: 400;
		margin-top: -5px;
		line-height: 24px;
		margin-bottom: 5px;
	}
	.item-metadata {
		color: $color-gray;
		font-size: 13px;
		font-family: $font-heading;
		a {
			color: $color-gray;
			&:hover {
				@extend %main_color;
			}
		}
	}
}
.middle-footer-page {
	.k-post-sm-thumb {
		.item {
			border-top-color:#363636;
		}
		.item-thumb {
			width: 80px;
			margin-right: 25px;
		}
		.item-title {
			margin-bottom: 10px;
		}
		.item-title {
			a {
				color: #ccc ;
				&:hover {
					@extend %main_color;
				}
			}
		}
		.item-metadata {
			color: #5e5e5e;
			font-size: 14px;
			a {
				color: #5e5e5e;
				&:hover {
					@extend %main_color;
				}
			}
		}
	}
}

.k-widget-info {
	.list-info {
		font-family: $font-heading;
		font-weight: 300;
		.fa {
			@extend %main_color;
			position: absolute;
			left: 0;
			top: 2px;
			font-size: 16px;
		}
		li {
			margin-bottom: 26px;
			padding-left: 27px;
			position: relative;
			font-size: 18px;
		}
	}
	.list-social {
		a {
			display: inline-block;
			width: 35px;
			height: 35px;
			line-height: 33px;
			text-align: center;
			border-radius: 50%;
			margin-right: 15px;
			margin-bottom: 15px;
			border: 1px solid $color-pri;
			@extend %main_color;
		}
	}
}

.middle-footer-page {
	padding-top: 75px;
	padding-bottom: 32px;
	.widget {
		margin-bottom: 43px;
	}
	.widget-title {
		font-size: 24px;
		margin-bottom: 30px;
		color: #ccc;
	}

	.k-widget-info {
		.list-social {
			overflow: hidden;
			a {
				@extend %main_color;
				@extend %main_bd_color;
				&:hover {
					color: #ccc;
					border-color: #ccc;
				}
			}
		}
	}
	.widget_tag_cloud {
		.tagcloud {
			a {

				border: 1px solid #696969;
				margin-right: 2px;
				margin-bottom: 3px;
				color: $color-gray;
				background: none;
				&:hover {
					@extend %main_bd_color;
					@extend %main_color;
				}
			}
		}
	}
}

.k-widget-portfolio-circle-img {
	.widget-content {
		background: url(assets/imgs/line.png) no-repeat center 80px;
	}
	.widget-header {
		margin-bottom: 30px;
	}
	.item {
		float: left;
		background: #fff;
		text-align: center;
	}
	.item-thumb {
		border-radius: 50%;
		padding: 5px;
		border: 1px dashed #b4b4b4;
		position: relative;
		overflow: hidden;
		&:before {
			position: absolute;
			top: 0;
			left: -75%;
			z-index: 2;
			display: block;
			content: '';
			width: 50%;
			height: 100%;
			background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
			background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
			-webkit-transform: skewX(-25deg);
			transform: skewX(-25deg);
		}
		&:hover {
			&:before {
				-webkit-animation: shine .75s;
				animation: shine .75s;
			}
		}
	}
	.item-size-1 {
		width: 162px;
		margin-top: 60px;
		margin-right: 98px;
	}
	.item-size-2 {
		width: 232px;
		margin-top: 60px;
		margin-right: 125px;
	}
	.item-size-3 {
		width: 147px;
		margin-top: 120px;
		margin-right: 95px;
	}
	.item-size-4 {
		width: 287px;
	}
	.item-title {
		font-size: 24px;
		margin: 0;
		margin-top: 13px;
		margin-bottom: 0;
	}
	.item-size-2,
	.item-size-4 {
		.item-title {
			margin-top: 20px;
		}
	}
}

@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}

.k-widget-portfolio-single-img {
	.overlay {
		background: #f6f6f6;
		overflow: hidden;
	}
	.item-content {
		padding-top: 55px;
  		padding-bottom: 55px;
	}

	.item-thumb {
		padding-left: 0;
		position: absolute;
		height: 100%;
		width: 100%;
		left: 50%;
		top: 0;
		 img {
		 	width: auto;
		 }
	}
	.item-title {
		font-size: 30px;
		line-height: 30px;
		margin-top: 0;
		margin-bottom: 20px;
		padding-right: 35%;
	}
	.item {
		position: relative;
		p {
			margin-bottom: 25px;
		}
	}
}

.k-widget-portfolio-icon {
}

.k-widget-post-carousel {
	.overlay {
		background: #f9f9f9;
	}
	.item-time {
		font-family: $font-heading;
	}
	.item-time-day {
		font-size: 36px;
		line-height: 28px;
		margin-bottom: 8px;
		@extend %main_color;
		display: block;
	}
	.item-time-mon-year {
		color: $color-black;
		font-weight: 700;
	}
	.item-title {
		font-size: 18px;
		line-height: 24px;
		position: relative;
		padding-bottom: 15px;
		margin-top: 15px;
		margin-bottom: 28px;
		&:before {
			content:'';
			width: 85px;
			height: 2px;
			@extend %main_bg_color;
			position: absolute;
			bottom: 0;
		}
	}
	.item {
		overflow: hidden;
		p {
			margin-bottom: 0;
			line-height: 24px;
			color: $color-grayer;
		}
	}
	.item-right {
		float: left;
		text-align: right;
		.row {
			margin-right: 0;
		}
		.item-thumb {
			float: right;
			padding-right: 0;
		}
		.item-title {
			&:before {
				right: 0;
			}
		}
	}
	.item-left {
		float: right;
		text-align: left;
		.row {
			margin-left: 0;
		}
		.item-thumb {
			float: left;
			padding-left: 0;
		}
		.item-title {
			&:before {
				left: 0;
			}
		}
	}
	.owl-controls {
		margin: 0;
		.owl-buttons {
			> div {
				height: 52px;
				width: 52px;
				line-height: 52px;
				background: $color-black;
				background: rgba(0,0,0,.4);
				border: 1px solid #fff;
				border: 1px solid rgba(255,255,255,.4);
				position: absolute;
				top: 50%;
				margin-top: -26px;
				font-size: 16px;
			}
			.owl-prev {
				left: 25%;
				margin-left: 8px;
			}
			.owl-next {
				right: 25%;
				margin-right: 7px;
			}
		}
	}
}

.k-widget-info-2 {
	.overlay {
		@extend %main_bg_color;
		line-height: 24px;
		font-size: 18px;
		color: #fff;
	}
	.item {
		overflow: hidden;
		padding: 30px 0;
		font-family: $font-heading;
	}
	.icon {
		height: 75px;
		width: 75px;
		font-size: 34px;
		@extend %main_color;
		background: #fff;
		text-align: center;
		line-height: 75px;
		margin-right: 30px;
		border-radius: 50%;
		float: left;
	}
	strong {
		color: #fff;
		font-weight: 600;
		display: block;
	}
	.item-marker {
		strong {
			display: inline-block;
		}
	}
	.item-envelope {
		float: right;
	}
	p {
		margin-top: 10px;
		margin-bottom: 0;
		overflow: hidden;
	}
}

.k-map {
	width: 100%;
	height: 400px;
}

.k-map-contact-form {
	position: relative;
	overflow: hidden;
	.k-map {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: 1;
	}
	.col-md-6 {
		background: #fff;
		background: rgba(255,255,255,.9);
		position: relative;
		padding: 70px 15px 80px 30px;
		z-index: 2;
		&:before {
			content:'';
			position: absolute;
			width: 100%;
			height: 100%;
			left: 100%;
			top: 0;
			background: #fff;
			background: rgba(255,255,255,.9);
		}
	}
	.widget-title {
		font-size: 36px;
		text-transform: uppercase;
		margin-bottom: 15px;
	}
	.widget-des {
		color: #a3a3a3;
		font-size: 17px;
		margin-top: 0;
		margin-bottom: 40px;
	}
	.form {
		padding-left: 15px;
	}
	.form-control {
		border: none;
		border-bottom: 1px solid #bebebe;
		height: 50px;
		color: $color-gray;
		background: none;
		padding-left: 0;
		@include placeholder($color-gray);
	}
	textarea.form-control {
		height: 115px;
	}
	.form-group {
		margin-bottom: 15px;
	}
	.form-message {
		margin-bottom: 50px;
	}

}
.form-submit {
	margin-bottom: 0;
	input[type="submit"] {
		@extend %main_bg_color;
		color: #fff;
		border: none;
		line-height: 46px;
		padding: 0 30px;
		font-family: $font-heading;
		font-size: $font-size-sm;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 2px;
		@include transition(all .3s);
		&:hover {
			background:$color-black;
		}
	}
}

.k-widget-map {
}

.k-widget-post-single-carousel {
	background: url(assets/imgs/22.png) no-repeat center top;
	text-align: center;
	.overlay {
		background: #fff;
		background: rgba(255,255,255,.82);
		padding: 75px 0 85px;
	}
	.widget-title {
		font-size: 60px;
		font-weight: 300;
		margin-bottom: 20px;
	}
	.item-title {
		font-size: 24px;
		line-height: 40px;
		font-weight: 300;
		margin-bottom: 15px;
	}
	.owl-controls {
		.owl-buttons {
			div {
				border-radius: 0;
				border: 1px solid #666;
				color: $color-black;
				width: 32px;
				height: 32px;
				line-height: 30px;
				margin: 0 1px;
				font-size: 18px;
			}
		}
	}
}

.k-widget-post-single-carousel-2 {
	margin: 75px 0 80px;
	padding-bottom: 35px;
	.widget-title {
		font-size: 30px;
		position: relative;
		padding-bottom: 10px;
		margin-left: 50%;
		margin-bottom: 35px;
		padding-left: 15px;
		&:before {
			content:'';
			width: 25px;
			height: 2px;
			background: $color-black;
			position: absolute;
			left: 15px;
			bottom: 0;
		}
	}
	h4 {
		font-size: 20px;
		line-height: 26px;
		margin-bottom: 15px;
	}
	p {
		color: $color-gray;
		line-height: 24px;
		margin-bottom: 0;
	}
	.item {
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
	.owl-carousel {
		margin: -100px 0 -60px;
		position: relative;
		z-index: 2;
		.owl-controls {
			margin: 0;
			.owl-buttons {
				position: absolute;
				right: -122px;
				bottom: 0;
				div {
					height: 60px;
					width: 60px;
					line-height: 60px;
					font-size: 22px;
					@extend %main_bg_color;
					margin:  0 0 0 1px;
					&:hover {
						background: $color-black;
					}
				}
			}
		}
	}
	.widget-content {
		position: relative;
		background: #f8f8f8;
		padding: 20px 0 0;
		&:before,
		&:after {
			content:'';
			width: 50%;
			height: 100%;
			position: absolute;
			top: 0;
			background: #f8f8f8;
		}
		&:before {
			right: 100%;
		}
		&:after {
			left: 100%;
		}
	}
}

.k-widget-user {
	.item {
		text-align: center;
	}
	.item-thumb {
		position: relative;
		z-index: 1;
	}
	.item-social {
		margin-top: -24px;
		position: relative;
		z-index: 2;
		> div {
			border: 5px solid #fff;
			border-left: none;
			overflow: hidden;
			display: inline-block;
			background: #fff;
		}
		a {
			height: 42px;
			width: 42px;
			color: #fff;
			@extend %main_bg_color;
			line-height: 42px;
			font-size: 18px;
			display: inline-block;
			float: left;
			margin: 0 0 0 5px;
			&.fa-facebook {
				&:hover {
					background: #3b5998;
				}
			}
			&.fa-twitter {
				&:hover {
					background: #00aced;
				}
			}
			&.fa-rss {
				&:hover {
					background: #ed7000;
				}
			}
			&.fa-google-plus {
				&:hover {
					background: #2c3e50;
				}
			}
			&.fa-dribbble {
				&:hover {
					background: #eb5d8c;
				}
			}
		}
	}
	.item-title {
		font-size: 24px;
		margin-bottom: 5px;
		margin-top: 10px;
	}
	.item-position {
		font-size: $font-size-base;
		color: $color-gray;
		text-transform: uppercase;
		border-bottom: 2px solid $color-pri;
		padding-bottom: 7px;
		margin-top: 0;
		display: inline-block;
		margin-bottom: 20px;
	}
	p {
		color: $color-grayer;
		margin-bottom: 0;
	}
}

.k-widget-progress {
	background: #f0f0f0 url(assets/imgs/bg-progress.png) no-repeat center top;
	.overlay {
		background: #fff;
		background: rgba(255,255,255,.15);
		padding: 73px 0;
	}
	.widget-title {
		font-size: 30px;
		margin-bottom: 20px;
	}
	.widget-des {
		font-size: 18px;
		margin-top: 0;
		color: #555;
		margin-bottom: 35px;
	}
	p {
		margin-bottom: 0;
		color: #7f7f7f;
		line-height: 24px;
	}
	.k-progress:last-child {
		margin-bottom: 0;
	}
}

.k-widget-number {
	margin: 80px 0;
	.list-item {
		border: 1px solid #dbdbdb;
		border-left: none;
		overflow: hidden;
	}
	.item {
		border-left: 1px solid #dbdbdb;
		float: left;
		width: 25%;
		text-align: center;
		font-family: $font-heading;
		padding: 25px 0 35px;
	}
	.item-number {
		font-size: 48px;
		@extend %main_color;
		display: block;
	}
	.item-text {
		font-size: 18px;
		color: $color-black;
	}
}

.k-widget-contact-form,
.k-widget-info-3 {
	.widget-title {
		font-size: 30px;
		margin-bottom: 35px;
	}
}

.k-widget-contact-form {
	.form-group	{
		margin-bottom: 15px;
	}
	.form-control {
		border-color: #dbdbdb;
		height: 50px;
	}
	textarea.form-control {
		height: 140px;
		margin-bottom: 30px;
	}
	form {
		.row {
			margin: 0 -8px;
			> div {
				padding: 0 8px;
			}
		}
	}
}

.k-widget-info-3 {
	.list-item {
		margin-bottom: 0;
	}
	.item {
		margin-top: 30px;
		&:first-child {
			margin-top:0;
		}
	}
	.icon {
		height: 50px;
		width: 50px;
		line-height: 48px;
		text-align: center;
		float: left;
		margin-right: 25px;
		border: 1px solid #85bedc;
		display: inline-block;
		@extend %main_color;
		font-size: 30px;
	}
	p {
		overflow: hidden;
		margin-bottom: 0;

	}
	strong, span{
		display: block;
	}
	strong {
		font-size: 20px;
		font-family: $font-heading;
		font-weight: 400;
		margin-bottom: 5px;
		margin-top: -4px;
	}
	span {
		color: $color-grayer;
		line-height: 24px;
	}
}

.k-widget-portfolio-filter {
	text-align: center;
	margin-top: 72px;
	margin-bottom: 80px;
	.widget-title {
		font-size: 36px;
		margin-bottom: 15px;
	}
	.widget-des {
		color: $color-grayer;
		padding:  0 25%;
		margin-bottom: 40px;
	}
	.masonry-filter {
		margin-bottom: 55px;
		a {
			display: inline-block;
			font-size: 13px;
			font-weight: 700;
			text-transform: uppercase;
			line-height: 38px;
			border: 1px solid #dedede;
			padding:  0 30px;
			color: $color-grayer;
			margin:  0 5px;
			letter-spacing: 2px;
			&:hover, &.active {
				@extend %main_color;
				@extend %main_bd_color;
			}
		}
	}
	.masonry-items {
		margin-bottom: 10px;
	}
	.item {
		padding-top: 15px;
		padding-bottom: 45px;
		overflow: hidden;
	}
	.item-thumb {
		position: relative;
		margin-bottom: 25px;
		overflow: hidden;
		&:hover {
			.overlay {
				opacity: 100;
    			filter: alpha(opacity=100);
    			@include scale(1);
			}
		}
	}
	.overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		@extend %main_bg_color;
    	background: rgba(12,134,198,.8);
    	opacity: 0;
    	filter: alpha(opacity=0);
		@include transition(all .3s);
		@include scale(.5);
		> div {
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -25px;
			height: 50px;
			width: 100%;
			text-align: center;
		}
	}
	.fa {
		border: 1px solid #fff;
		width: 50px;
		height: 50px;
		margin: 0 1px;
		line-height: 48px;
		color: #fff;
		font-size: 14px;
		&:hover {
			@extend %main_color;
			background: #fff;
		}
	}
	.item-title {
		font-size: 24px;
		margin-top: 0;
		margin-bottom: 15px;
	}
	h5 {
		font-size: 15px;
		color: $color-grayer;
		margin: 0;
		a {
			color: $color-grayer;
			&:hover {
				@extend %main_color;
			}
		}
	}
}

.k-widget-flickr {
	.flickr-wrap {
		margin-top: -15px;
		margin-left: -15px;
		li {
			float: left;
			margin-left: 15px;
			margin-top: 15px;
			width: 79px;
			height: 79px;
			a {
				display: inline-block;
				width: 79px;
				position: relative;
				i {
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					line-height: 79px;
					text-align: center;
					@extend %main_bg_color;
					color: #fff;
					font-size: 16px;
					opacity: 0;
					filter: alpha(opacity=0);
					@include transition(all .3s);
				}
				&:hover {
					i {
						opacity: 1;
						filter: alpha(opacity=100);
					}

				}
			}
		}
		ul {
			margin-bottom: 0;
		}
	}
}
.k-widget-pricing-table {
	&.has-background {
		background-image: url(assets/imgs/bg-widget-demo-2.png);
	}
}

.widget-icon-boxes {
	.widget-title {
		font-size: 30px;
		font-weight: 300;
		line-height: 36px;
		padding-bottom: 18px;
		margin-bottom: 20px;
		border-bottom: 1px solid $color-border;
	}
	.read-more-list {
		@extend %main_color;
		font-family: $font-heading;
		font-size: $font-size-sm;
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 2px;
	}
	.icon-box {
		margin-bottom: 0;
	}
}


.k-widget-full-width-single-carousel {
	background: url(assets/imgs/40.png) no-repeat center top;
	text-align: center;
	height: 660px;
	.overlay {
		position: relative;
		height: 100%;
		background: $color-black;
		background: rgba(0,0,0,.89);
	}
	.item-title {
		font-size: 36px;
		text-transform: uppercase;
		margin-bottom: 25px;
		margin-top: 287px;
		a {
			color: #fff;
		}
	}
	p {
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 30px;
		display: inline-block;
		width: 500px;
	}
	.read-more-border {
		border-color: #8f8f8f;
		color: #fff;
	}
	.owl-theme {
		height: 100%;
		@include controls_carousel(60px);
	}
}

.k-widget-slider-animation {
	z-index: 1;
	.slider-wrapper{
		position:relative;
		overflow:hidden;
		width:100%;
		background:$color-black;
		max-height: 500px;
	}
	p{
		position:absolute;
		top:-200px;
		z-index:9999;
		line-height: 24px;
		margin: 0;
		color: $color-black;
	}
	.read-more {
		color: #fff;
		height: 44px !important;
		line-height: 44px !important;
		border-color: #8f8f8f !important;
		&:after {
			color: #8f8f8f !important;
		}
		&:hover {
			border-color: $color-pri !important;
			color: $color-pri !important;
			&:after {
				color: $color-pri !important;
			}
		}
	}

	.fraction-slider .active-slide {
		z-index: 9;
	}
	.slider-font-size-base {
		font-size: $font-size-base;
	}
	.k-text-gray {
		color: $color-gray ;
	}
	.k-text-grayer {
		color: $color-grayer;
	}
	.k-text-black {
		color: $color-black ;
	}
	.k-text-white {
		color: #fff ;
	}
	/* slide 1 */
	.slider-font-size-1 {
		font-size: 30px;
		line-height: 1;
		color: #c0c0c0 !important;
	}
	.slider-font-size-2 {
		font-size: 60px;
		line-height: 1 !important;
	}
	.slider-text-color-1 {
		color: #828181 !important;
	}
	/* slide 2*/

	.slider-font-size-3 {
		font-size: 36px;
		line-height: 1;
	}

	.slider-font-size-4 {
		font-size: 18px;
		line-height: 1;
	}

	/* slide 3*/

	.slide-font-size-5 {
		font-size: 45px;
	}
	.slide-font-size-6 {
		font-size: 24px;
	}
	.slide-font-size-7 {
		font-size: 14px;
	}
	.slider-text-color-2 {
		color: #bcbcbc !important;
	}
	.slider-text-color-3 {
		color: #ccc !important;
	}
	.slider-text-color-8 {
		color: #888 !important;
	}

	.k-font-pri {
		font-family: $font-heading;
	}
	.k-font-second {
		font-family: $font-normal;
		font-weight: 300;
	}
	.slider{
		position:relative;
		width:100%;
		margin:0 auto;
		background: $color-black;
		z-index: 1;
	}
	.prev,
	.next {
		height: (60px);
		width: (60px);
		line-height: (60px - 2);
		margin: -(60px / 2) 0 0;
		border: 1px solid #555555;
		color: #555;
		font-size: 24px;
		position: absolute;
		top: 50%;
		z-index: 99;
		text-align: center;
		opacity: 0;
		filter: alpha(opacity=0);
		&:before {
			@extend .fa;
		}
		&:hover {
			@extend %main_bd_color;
			@extend %main_color;
		}
	}
	.prev {
		left: 0;
		&:before {
			@extend .fa-angle-left;
		}
	}
	.next {
		right: 0;
		&:before {
			@extend .fa-angle-right;
		}
	}
	&:hover {
		.prev,.next {
			opacity: 1;
			filter: alpha(opacity=100);
		}
	}
}

/* heading page */
.heading-page {
	background: url('assets/imgs/bg-header-1.png') center top no-repeat;
	margin-bottom: 80px;
	.overlay {
		background: rgba(0,0,0,.73);
		padding: 42px 0;
	}
	.heading-page-title {
		float: left;
		font-size: 30px;
		line-height: 1;
		color: #fff;
		font-weight: 400;
		margin: 0;
	}
	.kopa-breadcrumb {
		float: right;
		line-height: 30px;
		color: $color-gray;
		font-size: $font-size-base;
		font-family: $font-heading;
		a {
			color: $color-gray;
			&.current-page {
				color: #fff;
			}
			&:hover {
				color: #fff;
			}
		}
	}
}

/*
* 4.0 Header
*/
.sf-menu {
	> li {
		> ul {
			padding: 10px 5px;
			background: #fff;
			ul {
				background: #fff;
				margin-left: 8px;
			}
			> li {
				> a {
					border-left: none;
					&.sf-with-ul {
						&:after {
							@extend .fa;
							@extend .fa-angle-right:before;
							color: $color-grayer;
							font-size: 16px;
							border: none;
							margin-top: -8px;
						}

					}
				}
				&:first-child {
					> a {
						border-top: none;
					}
				}
			}
			li {
				background: none;
				&:hover,&.sfHover {
					background:none;
				}
			}
			a {
				line-height: 24px;
				padding: 13px 15px;
				border-top: 1px solid #ddd;
				color: $color-black;
				&:hover {
					@extend %main_color;
					&.sf-with-ul {
						&:after {
							@extend %main_color;
						}
					}
				}

			}
		}
		ul,.sf-mega {
			opacity: .9;
			filter: alpha(opacity=90);
		}
	}
}
.sf-mega {
	padding: 30px 30px 25px;
	background: #fff;
	ul {
		@extend .list-unstyled;
		li {
			border-bottom: 1px solid #ddd;
			background: none;
			&:hover {
				background:none;
			}
		}
		a {
			display: block;
			line-height: 24px;
			padding: 10px 20px 10px 0;
			position: relative;
			border: none;
			color: $color-black;
			i {
				font-size: 16px;
				margin-right: 5px;
			}
			&:after {
				@extend .fa;
				@extend .fa-plus:before;
				color: $color-grayer;
				position: absolute;
				right: 0;
				top: 17px;
				font-size: 10px;
			}
			&:hover {
				@extend %main_color;
				&:after {
					@extend %main_color;
				}
			}
		}
	}
}
.sf-mega-section {
	border-right: none;
	margin: 0;
	padding: 0 15px;
	&.sf-mega-section-1 {
		padding-right: 50px;
		font-weight: 600;
	}
	img {
		display: block;
		width: 100%;
		height: auto;
		margin-bottom: 15px;
	}
	h4 {
		font-size: 18px;
		margin-top: 0;
		position: relative;
		padding-bottom: 18px;
		margin-bottom: 12px;
		white-space: normal;
		a {
			padding: 0;
			color: $color-black;
			border: none;
			&:hover {
				@extend %main_color;
			}
		}
		&:before {
			content:'';
			width: 60px;
			height: 1px;
			background: $color-black;
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}
	span {
		font-size: 14px;
		color: $color-gray;
		display: inline-block;
		margin-bottom: 13px;
	}
	p {
		margin-bottom: 0;
		line-height: 24px;
		color: $color-grayer;
		white-space: normal;
	}

}
/* mobile menu */

.mm-panels {
	> .mm-panel {
		padding: 0 15px;
	}
}

#mobile-menu {
	font-family: $font-heading;
	display: none;
	&.mm-menu.mm-current {
		display: block;
	}
}

.header-page {
	font-family: $font-heading;
	.m-logo {
		display: none;
	}
	.inner-bottom-header-page,
	.inner-top-header-page {
		position: relative;
		@extend .clearfix;
	}
	&.header-page-1 {
		.top-header-page {
			padding: 25px 0;
		}
		.top-logo {
			float: left;
		}
		.info-c {
			float: right;
			li {
				float: left;
				margin-left: 30px;
				position: relative;
				padding-left: 45px;
			}
			.fa {
				border: 1px solid #cfcfcf;
				border-radius: 50%;
				height: 30px;
				width: 30px;
				line-height: 28px;
				text-align: center;
				@extend %main_color;
				position: absolute;
				top: 5px;
				left: 0;
			}
			strong, span {
				display: block;
			}
		}
		.bottom-header-page {
			@extend %main_bg_color;
			color: #fff;
			height: 55px;
		}
		.sb-search {
			@include search_box(55px);
		}
		.sf-menu {
			width: auto;
			position: static;
			> li {
				background: none;
				margin-left: 55px;
				&:hover,&:focus,&:active {
					background:none;
				}
				&:first-child {
					margin-left: 0;
				}
				> a {
					border: none;
					padding: 0;
					line-height: 55px;
					color: #fff;
					position: relative;
					font-size: 18px;
					&.sf-with-ul {
						&:after {
							display:none;
						}
					}
				}
				&.current-menu-item,&:hover {
					> a:before {
						content: '';
						width: 100%;
						height: 2px;
						background: #fff;
						position: absolute;
						left: 0;
						bottom: 0;
					}
				}
				&.current-menu-item {
					> a {
						font-weight: 600;
					}

				}

			}
		}
		.list-social {
			float: right;
			margin-right: 55px;
			a {
				opacity: 0.5;
				filter: alpha(opacity=50);
				display: block;
				margin-right: 20px;
				line-height: 55px;
				font-size: 16px;
				color: #fff;
				&:hover {
					opacity: 1;
					filter: alpha(opacity=100);
				}
				&.fa-facebook {
					&:hover {
						color: #3b5998;
					}
				}
				&.fa-twitter {
					&:hover {
						color: #00aced;
					}
				}
				&.fa-rss {
					&:hover {
						color: #ed7000;
					}
				}
				&.fa-google-plus {
					&:hover {
						color: #2c3e50;
					}
				}
				&.fa-dribbble {
					&:hover {
						color: #eb5d8c;
					}
				}
			}
		}
	}
	&.header-page-2 {
		.top-header-page {
			@extend %main_bg_color;
			color: #fff;
			height: 45px;
			a {
				color: #fff;
			}

		}
		.bottom-header-page {
			border-bottom: 3px solid #ddd;
			height: 103px;
			.sf-menu {
				float: right;
			}
		}
		.top-logo {
			line-height: 100px;
			float: left;
		}
		.info-c {
			margin-top: 10px;
			float: left;
			strong {
				color: #fff;
				font-weight: 400;
			}
			.fa {
				margin-right: 10px;
			}
			li {
				float: left;
				padding: 0 20px;
				border-left: 1px solid #fff;
				line-height: 25px;
				&:first-child {
					padding-left:0;
					border-left: 0;
				}
			}
		}
		.list-social {
			float: left;
			margin-right: 45px;
			a {
				opacity: 0.5;
				filter: alpha(opacity=50);
				display: block;
				margin-right: 20px;
				line-height: 45px;
				font-size: 16px;
			}
		}

		.sb-search {
			@include search_box(45px);
			.sb-search-input {
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}
		.sf-menu {
			font-size: 16px;
			width: auto;
			position: static;
			> li {
				background: none;
				border-right: 1px solid $color-border;
				&:hover,&:active,&focus {
					background:none;
				}
				&:first-child {
					border-left: 1px solid $color-border;
				}
				> a {
					padding:30px 25px 0 25px;
					position: relative;
					color: #212121;
					border: none;
					height: 100px;
					&:after {
						display:none !important;
					}
					> .fa {
						position: absolute;
						font-size: 14px;
						top: 65px;
						left: 50%;
						margin-left: -8px;
					}
				}

				&.current-menu-item,&:hover {
					> a {
						&:before {
							content:'';
							@extend %main_bg_color;
							width: 100%;
							height: 3px;
							position: absolute;
							left: 0;
							bottom: -3px;
						}
						.fa {
							@extend %main_color;
						}
					}
				}
				> ul {
					margin-top: 3px;
				}
			}
			.sf-mega {
				margin-top: 3px;
			}
		}
	}
	&.header-page-3 {
		position: absolute;
		width: 100%;
		left: 0;
		top: 0;
		z-index: 999;
		color: #fff;

		.top-header-page {
			height: 50px;
			a {
				color: #bbb;
			}
		}
		.inner-top-header-page {
			border-bottom: 1px solid #575757;
			padding: 13px 0 12px;
			overflow: hidden;
		}
		.bottom-header-page {
			.sf-menu {
				float: right;
			}
		}
		.top-logo {
			float: left;
			line-height: 114px;
		}
		.info-c {
			float: left;
			strong {
				color: #bbb;
				font-weight: 400;
			}
			.fa {
				margin-right: 10px;
				color: #bbb;
			}
			li {
				float: left;
				padding: 0 20px;
				border-left: 1px solid #575757;
				line-height: 25px;
				&:first-child {
					padding-left:0;
					border-left: 0;
				}
			}
		}
		.list-social {
			float: left;
			margin-right: 30px;
			a {
				color: #bbb;
				display: block;
				margin-right: 20px;
				line-height: 25px;
				font-size: 16px;
			}
		}

		.sb-search {
			@include search_box(25px);
			border-left: 1px solid #575757;
			width: 30px;
			top: 13px;
			.sb-icon-search,.sb-search-submit {
				background: none;
				color: #bbb;
				font-size: 14px;
				text-align: right;
				width: 30px;
			}
			.sb-search-input {
				background: none;
				padding-top: 0;
				padding-bottom: 0;
				@include placeholder(#bbb);
			}
		}
		.sf-menu {
			font-size: 16px;
			width: auto;
			position: static;
			> li {
				background: none;
				margin-left: 50px;
				&:hover,&:active,&focus {
					background:none;
				}
				> a {
					padding:0;
					line-height: 40px;
					position: relative;
					color: #c0c0c0;
					border: none;
					padding: 37px 0;
					text-transform: uppercase;
					&.sf-with-ul {
						padding-right: 25px;
						&:after {
							@extend .fa;
							@extend .fa-angle-down:before;
							border: none;
							margin-top: -8px;
						}
					}
				}
				&.current-menu-item,&:hover {
					> a {
						color: #fff;
					}
				}
			}
		}

	}
	&.header-page-4 {
		z-index: 9999;
		.top-header-page {
			background: #f5f5f5;
			color: $color-gray;
			border-top: 1px solid rgba(0,0,0,.16);
			height: 50px;
			padding: 12px 0 13px;
			a {
				color: $color-gray;
			}
		}
		.middle-header-page {
			position: relative;
			height: 100px;
			padding-top: 30px;
		}
		.bottom-header-page {
			height: 27px;
		}
		.inner-bottom-header-page {
			height: 54px;
			text-align: center;
			@extend %main_bg_color;
			position: relative;
			z-index: 999;
		}
		.sf-menu {
			display: inline-block;
			text-align: left;
			float: none;
			width: auto;
			position: static;
			> li {
				background: none;
				margin: 0 25px;
				&:hover,&:focus,&:active {
					background:none;
				}
				> a {
					font-size: 18px;
					color: #fff;
					border: none;
					padding: 0;
					line-height: 54px;
					&:after {
						display:none !important;
					}
				}
				&.current-menu-item,&:hover {
					> a:before {
						content: '';
						width: 100%;
						height: 2px;
						background: #fff;
						position: absolute;
						left: 0;
						bottom: 0;
					}
				}
			}
		}
		.info-ad {
			font-size: $font-size-base;
			line-height: 25px;
			float: left;
		}
		.info-c {
			overflow: hidden;
			li {
				position: relative;
				padding-left: 60px;
				height: 44px;
				float: right;
				padding-top: 3px;
				&:first-child {
					float:left;
				}
			}
			.fa {
				border-radius: 50%;
				height: 44px;
				width: 44px;
				line-height: 44px;
				text-align: center;
				color: #fff;
				@extend %main_bg_color;
				position: absolute;
				left: 0;
				top: 0;
				font-size: 18px;
			}
			strong, span {
				display: block;
			}
		}
		.top-logo {
			position: absolute;
			height: 104px;
			line-height: 104px;
			width: 300px;
			left: 50%;
			margin-left: -150px;
			top: 0;
		}
		.list-social {
			float: left;
			margin-right: 30px;
			a {
				color: $color-gray;
				display: block;
				margin-right: 20px;
				line-height: 25px;
				font-size: 16px;
			}
		}

		.sb-search {
			@include search_box(25px);
			border-left: 1px solid $color-gray;
			width: 30px;
			.sb-icon-search,.sb-search-submit {
				background: none;
				color: $color-gray;
				font-size: 14px;
				text-align: right;
				width: 30px;
			}
			.sb-search-input {
				background: none;
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}
	&.header-page-5 {
		.top-header-page {
			height: 44px;
			background: #252d3b;
			a {
				color: #bbb;
			}

		}
		.inner-top-header-page {
			padding: 10px 0;
			overflow: hidden;
		}
		.bottom-header-page {
			height: 95px;
			.sf-menu {
				float: right;
			}
		}
		.top-logo {
			line-height: 95px;
			float: left;
		}
		.info-c {
			float: left;
			strong {
				color: #bbb;
				font-weight: 400;
			}
			.fa {
				margin-right: 10px;
				color: #bbb;
			}
			li {
				float: left;
				padding: 0 20px;
				border-left: 1px solid #575757;
				line-height: 24px;
				&:first-child {
					padding-left:0;
					border-left: 0;
				}
			}
		}
		.list-social {
			float: left;
			margin-right: 30px;
			a {
				color: #bbb;
				display: block;
				margin-right: 20px;
				line-height: 25px;
				font-size: 16px;
			}
		}

		.sb-search {
			@include search_box(24px);
			border-left: 1px solid #575757;
			width: 30px;
			top: 10px;
			.sb-icon-search,.sb-search-submit {
				background: none;
				color: #bbb;
				font-size: 14px;
				text-align: right;
				width: 30px;
			}
			.sb-search-input {
				background: none;
				padding-top: 0;
				padding-bottom: 0;
				color: #bbb;
				@include placeholder(#bbb);
			}
		}
		.sf-menu {
			font-size: 16px;
			width: auto;
			position: static;
			> li {
				background: none;
				margin-left: 50px;
				&:hover,&:active,&focus {
					background:none;
				}
				> a {
					padding:0;
					line-height: 95px;
					position: relative;
					text-transform: uppercase;
					color: $color-grayer;
					border: none;
					font-weight: 700;
					&:after {
						display:none !important;
					}
				}
				&.current-menu-item,&:hover {
					> a {
						@extend %main_color;
						&:before {
							content: '';
							width: 100%;
							height: 2px;
							@extend %main_bg_color;
							position: absolute;
							left: 0;
							bottom: 0;
						}
					}
				}
				> ul {
					margin-top: 6px;
					padding: 0;
					@include box-shadow(0px 0px 5px 0px rgba(0,0,0,0.75));
					a {
						@extend %main_bg_color;
						color: #fff;
						border-top: 1px solid rgba(255,255,255,.45);
						&:after {
							color: #bbb;
						}
						&:hover {
							background: #252d3b;
							&:after {
								@extend %main_color;
							}
						}
					}
					li {
						&:first-child {
							> a {
								border-top: none;
							}
						}
					}
					.current-menu-item {
						> a {
							background: #252d3b;
							&:after {
								@extend %main_color;
							}
						}
					}
					ul {
						margin-left: 3px;
						@include box-shadow(0px 0px 5px 0px rgba(0,0,0,0.75));
						a {
							border-left: none;
						}

					}
				}

			}
			ul,.sf-mega {
				opacity: 1;
				filter: alpha(opacity=100);
			}
		}
	}
}

.info-c ul{
	margin-bottom: 0;
}
.list-social {
	a {
		float: left;
		display: inline-block;
	}
}

/*
* 5.0 Footer
*/

.top-footer-page {
	background: #252d3b;
	position: relative;
	&:before {
		content:'';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		right: 0;
		background: #252932;
	}
	.container .row {
		position: relative;
		&:before {
			content:'';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 33.3333333333%;
			right: 0;
			background: #252932;
		}
	}
}

.middle-footer-page {
	background: #20242c;
	color: #ccc;
}

.bottom-footer-page {
	background: #13151a;
	text-align: center;
	padding: 30px 0;
	.list-social {
		display: inline-block;
		overflow: hidden;
		margin-bottom: 0;
		margin-top: 15px;
		a {
			display: inline-block;
			float: left;
			color: #b4b4b4;
			font-size: 20px;
			margin: 0 15px;
			&:hover {
				@extend %main_color;
			}
		}
	}
}

.copyright {
	font-size: $font-size-sm;
	font-family: $font-heading;
	color: #b4b4b4;
	text-transform: uppercase;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 2px;
}


/**
 * 6.0 Pages
 */

/* background body page*/
body {
	&.single-page,
	&.blog-masonry-page {
		background: #f9f9f9;
	}

	&.blog-timeline-page,
	&.contact-page {
		.heading-page {
			margin-bottom: 0;
		}
		.footer-page {
			margin-top: 80px;
		}
	}
	&.portfolio-page {
		.heading-page {
			margin-bottom: 0;
		}
	}
	&.single-page,
	&.single-portfolio {
		.footer-page {
			margin-top: 80px;
		}
	}
}

.k-audio {
	background: #252932;
	padding: 20px;
}

/* blog page */

.list-blog-post {
	margin-bottom: 0;
	@extend .list-unstyled;
	.item {
		padding-top: 30px;
		padding-bottom: 30px;
		border-bottom: 1px solid $color-border;
		&:first-child {
			padding-top:0;
		}
		audio {
			display: block;
			width: 100%;
		}
		blockquote {
			padding: 25px 20px 30px;
			@extend %main_bg_color;
			color: #fff;
			font-family: $font-heading;
			font-size: $font-size-base;
			border-left: none;
			margin: 0 -20px;
			p {
				margin-bottom: 45px;
				line-height: 24px;
			}
			small {
				font-size: 18px;
				color: #fff;
				display: block;
				line-height: 28px;
				position: relative;
				padding-right: 30px;
				&:before {
					display: none;
				}
				&:after {
					@extend .fa;
					@extend .fa-quote-right:before;
					position: absolute;
					right: 0;
					top: 0;
					font-size: 32px;
				}
			}
		}
	}
	.item-thumb {
		position: relative;
		margin-bottom: 30px;
		overflow: hidden;
		img {
			@include transition(all .3s);
		}
		&:hover {
			img {
				@include scale(1.1);
			}
		}
	}
	.item-carousel {
		overflow: hidden;
	}
	.icon-sticky {
		position: absolute;
		top: 0;
		right: 0;
		@extend %main_bg_color;
		color: #fff;
		width: 30px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 16px;
		&:before,
		&:after {
			content:'';
			border-top: 15px solid $color-pri;
			position: absolute;
			bottom: -15px;
		}
		&:before {
			border-right: 15px solid transparent;
			left: 0;
		}
		&:after {
			border-left: 15px solid transparent;
			right: 0;
		}
	}
	.k-link {
		@extend %main_bg_color;
		color: #fff;
		margin-bottom: 20px;
		padding: 8px 20px 10px;
		p {
			font-family: $font-heading;
			font-size: 24px;
			line-height: 40px;
			position: relative;
			&:after {
				@extend .fa;
				@extend .fa-link:before;
				position: absolute;
				bottom: 0;
				right: 0;
				font-size: 30px;
			}
		}
		a {
			font-size: 14px;
			line-height: 24px;
			display: block;
			color: #fff;
			font-weight: 600;
			border-top: 1px solid #fff;
			border-top: 1px solid rgba(255,255,255,.6);
			padding-top: 3px;
		}
	}
	.item-title {
		font-size: 30px;
	}
	.item-metadata-2 {
		font-family: $font-heading;
		color: $color-black;
		margin-bottom: 15px;
		> span {
			span, i {
				color: #999;
			}
		}
		.fa {
			font-size: 14px;
		}
		a {
			color: #555;
			&:hover {
				@extend %main_color;
			}
		}
	}
	.item-text-content {
		color: #818181;
		margin-bottom: 28px;
		line-height: 24px;
	}
	&.list-blog-post-masonry {
		.item {
			padding-top: 0;
			padding-bottom: 0;
			border-bottom: none;
			margin-bottom: 30px;
			.item-inner {
				background: #fff;
			}
		}
		.item-title {
			font-size: 20px;
			line-height: 24px;
			margin-bottom: 2px;
		}
		.item-thumb {
			margin-bottom: 18px;
		}
		.item-content {
			border-bottom: 3px solid $color-pri;
			padding: 0 20px;
		}
		.item-text-content {
			margin-bottom: 16px;
		}
		.item-metadata > span {
			width: 60px;
			height: 60px;
			padding-top: 9px;
		}
		.item-metadata-2 {
			border-bottom: 1px solid $color-border;
			padding-bottom: 17px;
		}
		.item-metadata-3 {
			border-top: 1px solid $color-border;
			font-size: 14px;
			padding: 10px 20px;
			margin: 0 -20px;
		}
		.item-metadata-2,
		.item-metadata-3 {
			color: $color-gray;
			font-family: $font-heading;
			font-weight: 400;
			a {
				color: $color-gray;
				&:hover {
					@extend %main_color;
				}
			}
		}
	}
	&.list-blog-post-timeline {
		.item {
			border: none;
			position: relative;
			.item-inner {
				border: 1px solid $color-border;
				padding: 20px 20px 30px;
				background: #fff;
				position: relative;
				z-index: 2;
				overflow: hidden;
				@include transition(all .3s);
				&:hover {
					@extend %main_bd_color;
				}
			}
			&.item-link {
				.item-inner {
					padding-bottom: 0;
				}
				.item-metadata-3 {
					border-bottom: none;
					border-top: 1px solid $color-border;
					padding-top: 13px;
					padding-bottom: 15px;
				}
			}

		}
		.item-title {
			font-size: 20px;
			line-height: 24px;
			margin-bottom: 3px;
		}
		.item-thumb {
			margin-bottom: 25px;
		}
		.item-text-content {
			margin-bottom: 25px;
		}
		.item-metadata-2,
		.item-metadata-3 {
			border-bottom: 1px solid $color-border;
			padding-bottom: 20px;
			color: $color-gray;
			font-family: $font-heading;
			font-weight: 400;
			a {
				color: $color-gray;
				&:hover {
					@extend %main_color;
				}
			}
		}
	}
}
.k-timeline {
	position: relative;
	.line {
		position: absolute;
		top: 0;
		margin-left: -2px;
		width: 4px;
		height: 100%;
		background: #ddd;
		z-index: 1;
	}
	.item-line {
		height: 4px;
		width: 200px;
		background: #ddd;
		position: absolute;
		top: 50%;
		margin-top: -2px;
		right: 0;
		&:before,
		&:after {
			content:'';
			z-index: 2;
		}
		&:before {
			width: 18px;
			height: 18px;
			border: 3px solid #fff;
			@extend %main_bg_color;
			border-radius: 50%;
			position: absolute;
			top: -7px;
		}
		&:after {
			width: 20px;
			height: 20px;
			border-radius: 50%;
			border: 1px solid $color-pri;
			position: absolute;
			top: -8px;
		}
	}
	.item-time {
		border: 4px solid #ddd;
		background: #fff;
		width: 170px;
		text-align: center;
		line-height: 44px;
		color: $color-black;
		font-size: 18px;
		font-family: $font-heading;
		position: absolute;
		top: 25%;
		margin-top: -28px;
		z-index: 2;
		text-transform: uppercase;
	}
	.icon-down {
		text-align: center;
		border-radius: 50%;
		@extend %main_bg_color;
		color: #fff;
		position: absolute;
		z-index: 2;
		font-size: 18px;
		font-weight: 600;
	}
	&.two-col {
		.line {
			left: 50%;
		}
		.item {
			float: left;
			margin: 70px 0 0;
			padding: 0 15px 0 8.33333%;
			width: 50%;
			@include transition(all .3s);
			&.item-left {
				padding-left: 15px;
				padding-right: 8.33333%;
			}
			&.item-right {
			}
		}
		.icon-down {
			height: 40px;
			width: 40px;
			line-height: 40px;
			left: 50%;
			margin-left: -20px;
			top: -20px;
		}
		.item-left {
			.item-line {
				right: 0;
				&:before {
					right: -9px;
				}
				&:after {
					right: -10px;
				}
			}
			.item-time {
				right: -85px;
			}
		}
		.item-right {
			.item-line {
				left: 0;
				&:before {
					left: -9px;
				}
				&:after {
					left: -10px;
				}
			}
			.item-time {
				left: -85px;
			}
		}
	}
	&.one-col {
		.line {
			left:96px;
		}
		.item {
			margin-left: 192px;
			margin-top: 55px;
			padding: 0;
			&:first-child {
				margin-top:70px;
			}
		}
		.item-line {
			left: -96px;
			top: 95px;
			margin-top: 0;
			&:before {
				left: -9px;
			}
			&:after {
				left: -10px;
			}
		}
		.item-time {
			left: -96px;
			margin-left: -85px;
			top: 0;
			margin-top: 0;
		}
		.icon-down {
			height: 70px;
			width: 70px;
			line-height: 70px;
			left: 96px;
			top: -35px;
			margin-left: -35px;
			text-transform: uppercase;
		}

		.k-pagination {
			position: relative;
			z-index: 2;
			text-align: left;
			padding-left: 96px;
			.read-more {
				width: 140px;
				padding: 0;
				margin-left: -70px;
				text-align: center;
			}
		}
	}
}

/* list product page */

.k-item-product {
	text-align: center;
	.k-item-product-thumb {
		position: relative;
		overflow: hidden;
		margin-bottom: 25px;
		a {
			display: block;
		}
		img {
			display: block;
			width: 100%;
			height: auto;
			@include transition(all .3s);
		}

		&:hover {
			img {
				@include scale(1.1);
			}
			.k-product-buttons {
				@include scale(1);
				opacity: 100;
				filter: alpha(opacity=100);
			}
		}
	}
	.k-product-buttons {
		width: 183px;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -91px;
		@include transition(all .3s);
		opacity: 0;
		filter: alpha(opacity=0);
		@include scale(.5);
	}
	p.k-item-product-price {
		font-family: $font-heading;
		margin-bottom: 0;
		.k-item-product-price {
			font-weight: 700;
			@extend %main_color;
			margin-top: 15px;
		}
		.k-item-product-price-original {
			text-decoration: line-through;
			color: $color-gray;
			margin-right: 10px;
		}
	}
	.k-item-product-title {
		font-size: 20px;
		line-height: 24px;
		margin-top: 0;
		margin-bottom: 8px;
	}
}

.k-widget-related-product {
	.owl-item {
		padding: 0 15px;
	}
	.outer-carousel {
		margin: 0 -15px;
	}
	.widget-content {
		overflow: hidden;
	}
	.owl-carousel {
		@include controls_carousel(50px);
		.owl-controls {
			.owl-buttons {
				.owl-prev {
					left: 15px;
				}
				.owl-next {
					right: 15px;
				}
			}
		}
	}
}

.k-product-result-count {
	float: right;
	line-height: 24px;
	padding: 13px 0;
}
.k-product-order {
	float: left;
	.k-orderby {
		background: #f8f8f8;
		border-color: #f8f8f8;
		width: 285px;
		height: 50px;
		font-size: 18px;
		font-family: $font-heading;
	}
}
.k-list-product {
	margin-top: 55px;
	border-bottom: 1px solid $color-border;
	.k-item-product {
		margin-bottom: 50px;

	}
}

.k-star-rating {
	width: 70px;
	display: inline-block;
	font-size: $font-size-base;
	position: relative;
	span {
		font-size: $font-size-base;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		display: block;
		overflow: hidden;
		&:before {
			display: inline-block;
			font: normal normal normal 18px/1 FontAwesome;
			font-size: inherit;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\f005\f005\f005\f005\f005";
			@extend %main_color;
		}
	}
	&:before {
		display: inline-block;
		font: normal normal normal 18px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		content: "\f006\f006\f006\f006\f006";
		@extend %main_color;
	}
}

.entry-product {
	overflow-x: hidden;
	border-bottom: 1px solid #dedede;
	padding-bottom: 70px;
	.col-left {
		padding-right: 20px;
	}
	.col-right {
		padding-left: 20px;
		position: relative;
	}
	#product_zoom {
		position: absolute;
		z-index: -1;
		width: 500px;
		height: 500px;
		left: 15px;
		top: 0;
	}
	.entry-thumb {
		position: relative;
		margin-bottom: 30px;
		overflow: hidden;
	}
	.entry-title {
		font-size: 24px;
		line-height: 30px;
		margin-bottom: 15px;
		margin-top: 0;
	}
	p.price,
	.k-product-rating {
		float: left;
		margin-bottom: 28px;
		font-size: 20px;
	}
	.k-product-rating {
		margin: -3px 0 0 20px;
		.k-star-rating {
			margin: 5px 0 0;
		}
	}
	p.price , span.price {
		font-size: 20px;
		del {
			opacity: 1;
			filter: alpha(opacity=100);
			margin-right: 20px;
		}
		ins {
			color: $color-black;
			font-weight: 400;
		}
	}
	.product-info {
		margin-bottom: 30px;
	}
	.quantity {
		height: 46px;
		width: 100px;
		text-align: center;
		float: left;
		margin-right: 10px;
		.fa {
			background: #f8f8f8;
			display: inline-block;
			float: left;
			line-height: 46px;
			width: 32px;
			color: $color-gray;
			cursor: pointer;
			font-size: 14px;
			@include transition(all .3s);
			&:hover {
				@extend %main_bg_color;
				color: #fff;
			}
		}
		.qty {
			float: left;
			border: none;
			padding: 0;
			height: 46px;
			line-height: 46px;
			color: $color-gray;
			width: 36px;
			background: #f8f8f8;
			display: inline-block;
			text-align: center;
		}
	}
	.add-to-cart-button {
		border-radius: 0;
		font-size: 12px;
		text-transform: uppercase;
		font-family: $font-heading;
		font-weight: 400;
		padding: 0 33px;
		line-height: 46px;
		border: none;
		&:hover {
			background: $color-black;
		}
	}
	.k-product_meta {
		@extend .list-unstyled;
		border-bottom: 1px solid #eee;
		text-transform: uppercase;
		font-family: $font-heading;
		color: $color-black;
		margin-bottom: 0;
		li {
			line-height: 26px;
			padding: 10px 0;
			border-top: 1px solid #eee;
		}
		a {
			text-transform: none;
			color: #878787;
			&:hover {
				@extend %main_color;
			}
		}
		.fa {
			padding: 0 5px;
		}
	}
	.panel-title {
		font-size: $font-size-sm;
		font-weight: 700;
		text-transform: uppercase;
		line-height: 24px;
		letter-spacing: 2px;
	}
	.panel-default > .panel-heading + .panel-collapse > .panel-body {
		border: none;
	}
	.panel-group .panel + .panel {
		margin-top: 10px;
	}
	.panel-body {
		padding-left: 0;
		padding-right: 0;
	}
	#product-summary {
		text-align: center;
		.panel-default {
			border: none;
			@include box-shadow(none);
			> .panel-heading {
				border: none;
				padding: 13px 20px;
			}
		}
	}

	#sync1 {
		margin-bottom: 15px;
		img {
			max-width: 100%;
			height: auto;
		}
	}
	.wrap-sync2 {
		overflow: hidden;
		.outer-carousel {
			margin-left: -15px;
		}
	}
	#sync2 {
		img {
			display: block;
			width: 100%;
			height: auto;
		}
		.owl-item {
			padding: 0 0 0 15px;
		}
		@include controls_carousel(40px);
		.owl-controls {
			.owl-buttons {
				.owl-prev {
					left: 15px;
				}
			}
		}
	}

	.form-add-to-cart{
		margin-bottom: 45px;
	}
	.k-entry-summary {
		margin-bottom: 50px;
		p {
			line-height: 24px;
		}
	}

	.comments-area {
		text-align: left;
		.k-star-rating {
			margin-left: 10px;
		}
	}
}

.k-stars {
	a {
		border-right: 1px solid $color-border;
		margin-right: 10px;
		padding-right: 10px;
		display: inline-block;
		&:last-child {
			border-right: none;
		}
	}
	a.star-1 {
		@extend %main_color;
		&:after {
			content: "\f006";
		}
		&:hover {
			&:after {
				content: "\f005";
			}
		}

	}
	a.star-2 {
		&:after {
			content: "\f006\f006";
		}
		&:hover {
			&:after {
				content: "\f005\f005";
			}
		}

	}
	a.star-3 {
		&:after {
			content: "\f006\f006\f006";
		}
		&:hover {
			&:after {
				content: "\f005\f005\f005";
			}
		}

	}
	a.star-4 {
		&:after {
			content: "\f006\f006\f006\f006";
		}
		&:hover {
			&:after {
				content: "\f005\f005\f005\f005";
			}
		}

	}
	a.star-5 {
		&:after {
			content: "\f006\f006\f006\f006\f006";
		}
		&:hover {
			&:after {
				content: "\f005\f005\f005\f005\f005";
			}
		}

	}
}
.k-stars a.star-1:after,
.k-stars a.star-2:after,
.k-stars a.star-3:after,
.k-stars a.star-4:after,
.k-stars a.star-5:after {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* single page */

.entry-post,
.entry-page {
	.entry-thumb {
		position: relative;
		margin-bottom: 30px;
		img {
			display: block;
			max-width: 100%;
			height: auto;
		}
	}

	.entry-title {
		font-size: 28px;
		line-height: 35px;
		margin-top: 0;
		margin-bottom: 5px;
	}

	.entry-metadata {
		font-family: $font-heading;
		margin-bottom: 25px;
		a {
			color: $color-black;
			&:hover {
				@extend %main_color;
			}
		}
	}

}
.entry-content,.tab-content,.panel-body {
	@include format-content();
}

/* single portfolio */
.entry-portfolio {
	.entry-thumb {
		img {
			display: block;
			max-width: 100%;
			height: auto;
		}
	}
	.entry-content {
		margin-bottom: 40px;
		p {
			color: #818181;
		}
	}
	.entry-title {
		font-size: 24px;
		margin-top: 0;
		margin-bottom: 17px;
	}
	.box-controls {
		overflow: hidden;
		a {
			float: left;
			margin-right: 5px;
			display: inline-block;
			border: 1px solid #ddd;
			height: 36px;
			width: 36px;
			text-align: center;
			line-height: 34px;
			color: #818181;
			&:hover {
				@extend %main_bd_color;
				@extend %main_color;
			}
		}
	}
	.read-more {
		margin-bottom: 40px;
	}
	.box-meta {
		font-size: 18px;
		color: #818181;
		margin-bottom: 25px;
		span {
			color: $color-black;
		}
	}
	&.entry-portfolio-2 {
		.entry-thumb {
			margin-bottom: 50px;
		}
		.box-meta {
			margin-bottom: 45px;
		}
		.entry-content {
			margin-bottom: 32px;
		}
	}
}
.entry-thumb, .list-blog-post {
	.single-carousel {
		img {
			display: block;
			width: 100%;
			height: auto;
		}
		.owl-controls {
			margin: 0;
			.owl-buttons {
				div {
					margin: -18px 0 0;
					background: #000;
					background: rgba(0,0,0,.56);
					width: 36px;
					height: 36px;
					color: #fff;
					position: absolute;
					top: 50%;
					font-size: 18px;
					line-height: 36px;
				}
				.owl-prev {
					left: 0;
				}
				.owl-next {
					right: 0;
				}
			}
		}
	}
}

/* post navigation */
.k-post-navigation {
	.nav-previous,
	.nav-next {
		position: fixed;
		top: 50%;
		margin-top: -50px;
		z-index: 9;
		div {
			position: absolute;
			top: 0;
			padding: 14px 15px;
			background: #2165a4;
			color: #fff;
			font-family: $font-heading;
			overflow: hidden;
			width: 265px;
			@include transition(all .3s);
			z-index: 1;
		}
	}
	.nav-previous {
		left: 0;
		div {
			left: -300px;
			img {
				float: right;
				margin-left: 15px;
			}
			.post-title {
				float: left;
				width: 120px;
				display: inline-block;
				text-align: right;
			}
		}
		&:hover {
			div {
				left:46px;
			}
		}
	}
	.nav-next {
		right: 0;
		div {
			right: -300px;
			img {
				float: left;
				margin-right: 15px;
			}
			.post-title {
				float: right;
				width: 120px;
				display: inline-block;
			}
		}
		&:hover {
			div {
				right:46px;
			}
		}
	}
	.meta-nav {
		height: 100px;
		width: 46px;
		line-height: 100px;
		@extend %main_bg_color;
		color: #fff;
		font-size: 24px;
		text-align: center;
		position: relative;
		z-index: 2;
	}
	img {
		width: 100px;
		height: auto;
		max-height: 72px;
	}
}
/* box tags post */
.box-tags-post {
	font-family: $font-heading;
	margin-bottom: 50px;
	margin-top: 25px;
	@extend .clearfix;
	a {
		display: inline-block;
		margin-right: 6px;
		margin-bottom: 5px;
		line-height: 44px;
		padding: 0 12px;
		color: #fff;
		@extend %main_bg_color;
		float: left;
		&:hover {
			background: $color-black;
		}
	}
}

/* box share post */
.box-share-post {
	border-top: 1px solid $color-border;
	border-bottom: 1px solid $color-border;
	padding: 28px 0;
	overflow: hidden;
	margin-bottom: 55px;
	h4 {
		font-size: 18px;
		line-height: 24px;
		float: left;
		margin: 0;
	}
	.list-social {
		float: right;
		a {
			display: inline-block;
			width: 32px;
			height: 24px;
			line-height: 24px;
			text-align: center;
			float: left;
			color: $color-black;
			font-size: 18px;
			&:hover {
				@extend %main_color;
			}
		}

	}
}

/* box-user-post */
.box-user-post {
	background: #fff;
	border: 1px solid $color-border;
	padding: 30px 30px 30px 160px;
	position: relative;
	margin-bottom: 50px;
	.box-user-avatar {
		width: 115px;
		position: absolute;
		left: 30px;
		right: 30px;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
	.box-user-title {
		font-size: 20px;
		margin-top: 0;
		margin-bottom: 10px;
		line-height: 1;
	}
	.list-social {
		overflow: hidden;
		a {
			display: inline-block;
			border: 1px solid $color-border;
			color: $color-black;
			font-size: 18px;
			float: left;
			margin-right: 4px;
			width: 32px;
			height: 32px;
			text-align: center;
			line-height: 30px;
			&:hover {
				@extend %main_color;
				@extend %main_bd_color;
			}
		}
	}
}

/* related post */
.k-related-post {
	padding-bottom: 30px;
	margin-bottom: 50px;
	border-bottom: 1px solid $color-border;
	.widget-title {
		font-size: 24px;
		margin-bottom: 25px;
	}
	.item {
		margin-bottom: 20px;
	}
	.item-thumb {
		margin-bottom: 30px;
		overflow: hidden;
		img {
			@include transition(all .3s);
			&:hover {
				@include scale(1.1);
			}
		}
	}
	.item-title {
		font-size: 18px;
		line-height: 24px;
		margin-top: 0;
		margin-bottom: 10px;
	}
	.item-metadata {
		color: $color-gray;
		a {
			color: $color-gray;
			&:hover {
				@extend %main_color;
			}
		}
	}
}

/* comment */
.comment-reply-title small a{
    float: right;
}

.comments-area {
	> :last-child {
		margin-bottom: 0;
	}
}

.comment-respond{
	padding-top: 50px;
	border-top: 1px solid $color-border;
	.form-control {
		height: 52px;
		background: none;
		&:focus,
		&:active {
			border-color:$color-black;
		}
	}
	textarea.form-control {
		height: 240px;
	}
	.form-submit {
		margin-top: 30px;
		input[type="submit"] {
			line-height: 52px;
			font-size: 18px;
			font-weight: 400;
			text-transform: none;
		}
	}
}

.comments-area {
	.comments-title,
	.comment-reply-title {
		font-size: 24px;
		margin-top: 0;
		margin-bottom: 25px;
	}
	.fn {
		font-weight: 400;
		font-family: $font-heading;
		margin-bottom: 10px;
		display: block;
	}
	.k-pagination {
		margin: 55px 0;
		a, .prev, .next {
			color: #555;
			&:hover {
				@extend %main_color;
			}
		}
	}

}

.comment-list {
	@extend .list-unstyled;
	margin: 0;
	.comment-body,
	.pingback,
	.trackback {
		border-top: 1px solid $color-border;
		padding: 25px 0;
	}
	.children {
		@extend .list-unstyled;
		margin: 0 0 0 85px;
	}

	.comment {
		&:last-child {
			.comment-body {
				border-bottom: 1px solid $color-border;
			}
			&.parent {
				.comment-body {
					border-bottom: none;
				}
			}
		}
	}
}

.comment-author {
	color: $color-border;
	margin-bottom: 0.4em;
	a {
		font-size: 18px;
		font-family: $font-heading;
		@extend %main_color;
		&:hover {
			color: $color-black;
		}
	}
	.avatar {
		float: left;
		height: 70px;
		margin-right: 15px;
		width: 70px;
	}
}

.comment-list {
	.comment-edit-link,
	.reply a {
		font-size: 12px;
		font-family: $font-heading;
		font-weight: 600;
		@extend %main_color;
		text-transform: uppercase;
		&:hover {
			color: $color-black;
		}
	}

	.reply,
	.comment-content {
		padding-left: 85px;
	}

	.reply {
		margin-top: 10px;
	}
	> .comment {
		&:first-child {
			.comment-body {
				border-top: none;
				padding-top: 0;
			}
		}
	}
	.comment-content {
		p {
			color: #818181;
		}
	}
}

.comment-metadata,
.pingback .edit-link {
	color: $color-gray;
	font-size: 12px;
	font-family: $font-heading;
	font-weight: 600;
	text-transform: uppercase;
}

.comment-metadata a,
.pingback .edit-link a {
	color: $color-gray;
}

.comment-metadata a:hover,
.pingback .edit-link a:hover {
	@extend %main_color;
}

.comment-metadata {
	margin-bottom: 15px;
}

.comment-metadata .edit-link {
	margin-left: 15px;
}

.pingback .edit-link {
	margin-left: 1em;
}

.pingback .edit-link:before {
	top: 5px;
}


.logged-in-as a:hover {
	border-bottom: 1px solid $color-border;
}

.no-comments {
	border-top: 1px solid $color-border;
	color: $color-gray;
	font-weight: 700;
	padding-top: 25px;
}

/* validate form */

.form-group {
	position: relative;
	label.error {
		position: absolute;
		right: 10px;
		top: 12px;
		line-height: 24px;
		color: red;
		font-weight: 400;
	}
}
.k-list {
	li {
		margin-top: 30px;
		&:first-child {
			margin-top: 0;
		}
	}
	.fa {
		height: 30px;
		width: 30px;
		line-height: 28px;
		text-align: center;
		margin-right: 15px;
		border: 1px solid #c5c5c5;
		border-radius: 50%;
	}
}
.k-list-inline {
	@extend %main_color;
	margin-left: 0;
	li {
		margin-right: 20px;
		padding-left: 10px;
		position: relative;
		&:before {
			content:'';
			width: 3px;
			height: 3px;
			margin-right: 7px;
			display: block;
			position: absolute;
			top: 9px;
			left: 0;
			@extend %main_bg_color;
			border-radius: 5px;
		}
	}
}
/* service page*/
.service-tabs {
	margin: 0 0 80px;
	.nav-tabs {
		margin-bottom: 25px;
		font-family: $font-heading;
		border-bottom: none;
		> li {
			float: none;
			display: block;
			margin-bottom: 5px;
			padding: 0;
			> a {
				display: block;
				margin: 0;
				border: none !important;
				line-height: 24px;
				padding: 15px 25px;
				background: #f5f5f5;
				color: #555;
				border-radius: 0;
				&:after {
					@extend .fa;
					@extend .fa-angle-right:before;
					position: absolute;
					right: 25px;
					top: 20px;
					color: #f5f5f5;
				}
				&:hover {
					@extend %main_bg_color;
					color: #fff;
					border: none;
					&:after {
						color: #fff;
					}
				}
			}
			&.active {
				> a {
					@extend %main_bg_color;
					color: #fff;
					&:after {
						color: #fff;
					}
				}
			}
		}
	}

	.box {
		margin-bottom: 75px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	.box-image {
		margin-bottom: 35px;
	}

	.box-title {
		font-size: 30px;
		margin-bottom: 45px;
		margin-top: 0;
		padding-bottom: 20px;
		font-weight: 400;
		position: relative;
		&:before {
			content:'';
			width: 25px;
			height: 2px;
			background: $color-black;
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}
	.k-list {
		margin-top: 35px;
	}
}

/*
* 7.0 Fix ie
*
 */
.no-borderradius {
	.container {
	width: 1176px;
	}
	.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
	    float: left;
	    position: relative;
	  min-height: 1px;
	  padding-left: 15px;
	  padding-right: 15px;
	}

  .col-md-1 {
    width: 8.33333% !important; }

  .col-md-2 {
    width: 16.66667% !important; }

  .col-md-3{
    width: 25% !important; }

  .col-md-4 {
    width: 33.33333% !important; }

  .col-md-5 {
    width: 41.66667% !important; }

  .col-md-6 {
    width: 50% !important; }

  .col-md-7 {
    width: 58.33333% !important; }

  .col-md-8 {
    width: 66.66667% !important; }

  .col-md-9 {
    width: 75% !important; }

  .col-md-10 {
    width: 83.33333% !important; }

  .col-md-11 {
    width: 91.66667% !important; }

  .col-md-12 {
    width: 100% !important; }


  .col-md-offset-1 {
    margin-left: 8.33333% !important; }

  .col-md-offset-2 {
    margin-left: 16.66667% !important; }

  .col-md-offset-3 {
    margin-left: 25% !important }

  .col-md-offset-4 {
    margin-left: 33.33333% !important; }

  .col-md-offset-5 {
    margin-left: 41.66667% !important; }

  .col-md-offset-6 {
    margin-left: 50% !important; }

  .col-md-offset-7 {
    margin-left: 58.33333% !important; }

  .col-md-offset-8 {
    margin-left: 66.66667% !important; }

  .col-md-offset-9 {
    margin-left: 75% !important; }

  .col-md-offset-10 {
    margin-left: 83.33333% !important; }

  .col-md-offset-11 {
    margin-left: 91.66667% !important; }

  .col-md-offset-12 {
    margin-left: 100% !important; }


}

/*--- 404 ---*/



.error-search-form {
	.row{
		margin: 0 -4px;
		> div{
			padding: 0 4px;
		}
	}
    input[type="text"] {
        border: 1px solid $color-border;
        color: $color-gray;
        padding: 0 20px;
        height: 52px;
        line-height: 52px;
        font-size: 16px;
        font-style: italic;
        width: 100%;
        @include transition(all .3s);
    }
    input[type="submit"] {
        border: none !important;
        height: 52px;
        line-height: 52px;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        background: $color-black;
        @include transition(all .3s);
        &:hover {
            @extend %main_bg_color;
        }
    }
}
.kopa_module_404{
	margin: 80px 0;
    text-align: center;
    .widget-content{
        > header{
            h3 {
                font-size: 34px;
                line-height: 42px;
            }
            a{
                @extend %main_color;
                &:hover{
                    color: $color-black;
                }
            }
        }
        > p{
            font-size: 240px;
            line-height: .8em;
            font-weight: 600;
            @extend %main_color;
            margin: 40px 0 60px;
            @media screen and (max-width: 639px) {
                font-size: 100px;
            }
        }
    }
}

/*--- More Custom ---*/

.icon-box {
    &.style-2 {
        .icon {
            @extend %main_color;
            @extend %main_bg_color;
        }
    }
}
.header-page {
    &.header-page-4 {
        .top-header-page {
            @extend %main_bg_color;
        }
    }
}
.sb-icon-search, .sb-search-submit {
	background: #0d77ae;
}

/*
* 8.0 Responsive
*
*/

@media screen and (min-width: 320px) {

	/* menu */
	.sf-menu {
		display: none;
	}
	.mobile-menu {
		font-size: 20px;
		color: $color-black;
	}
	/* end menu */

	/* widget */
	.k-widget-testi {
		.item {
			padding: 0;
		}
	}

	.k-widget-portfolio-single-img {
		.item-title {
			padding-right: 0;
		}
		.item-thumb {
			position: relative;
			height: auto;
			width: auto;
			top: auto;
			left: auto;
			margin: 85px -15px 0;
		}
		.item-content {
			padding-bottom: 0;
		}
	}

	.k-widget-info-2 {
		.item {
			display: table;
		}
		p {
			display: table-cell;
			vertical-align: middle;
			width: 100%;
		}
	}
}

@media screen and (min-width: 768px) {
	/* widget */
	.k-widget-portfolio-single-img {
		.item-content {
			padding-bottom: 55px;
		}
		.item-thumb {
			position: absolute;
			height: 100%;
			width: 100%;
			left: 50%;
			top: 0px;
			margin: 0;
		}
	}
}

@media screen and (min-width: 1024px) {
	/* header */
	.header-page {
		&.header-page-3,
		&.header-page-4,
		&.header-page-5 {
			.sb-search {
				position: relative;
				float: left;
				top: auto;
			}
			.list-social {
				margin-right: 0;
			}
		}
	}
	/* menu */
	.sf-menu {
		display: block;
	}
	#mobile-menu,
	.mobile-menu {
		display: none;
	}
	html.mm-opening {
		.mm-slideout {
			@include translate(0,0);
			min-height: auto !important;
		}
	}
	html.mm-blocking {
		#mm-blocker {
			display: none;
		}
	}

	/* component */
	.icon-box {
		&.style-1 {
			.icon {
				float: none;
				margin-right: 0;
			}
			h3 {
				margin-top: 0;
				margin-bottom: 10px;
			}
		}
	}

	/* widget */
	.k-widget-testi {
		.item {
			padding: 0 10%;
		}
	}
}

@media screen and (min-width: 1280px) {
	/* widget */
	.k-widget-info-2 {
		p {
			width: auto;
		}
	}
}

@media screen and (min-width: 320px) and (max-width: 400px) {

}
@media screen and (min-width: 320px) and (max-width: 767px) {
	/* header */
	.header-page {
		&.header-page-1 {
			.top-header-page {
				display: none;
			}

			.mobile-menu {
				right: 80px;
				top: 50%;
				margin-top: -12px;
				position: absolute;
			}

			.m-logo {
				display: inline-block;
				line-height: 50px;
				img {
					max-height: 55px;
					width: auto;
					display: inline-block;
				}
			}
			.list-social {
				display: none;
			}
		}
	}
	/* component*/
	.icon-box {
		&.style-1 {
			.icon {
				float: left;
				margin-right: 25px;
			}
			h3 {
				overflow: hidden;
				margin-top: 20px;
				margin-bottom: 0;
			}
			.read-more {
				display: none;
			}
		}
	}
	/* widget */
	.k-widget-post-carousel {
		.item {
			text-align: center;
			.row {
				margin-left: -15px;
				margin-right: -15px;
				> div {
					padding-left: 15px;
					padding-right: 15px;
				}
			}
			.item-title {
				&:before {
					right: 50%;
					left: auto;
					margin-right: -42px;
				}
			}
		}
		.item-thumb {
			margin-bottom: 30px;
		}
	}

	.k-timeline {
		.icon-down {
			left: 50%;
			font-size: 13px;
		}

		&.two-col {
			.item {
				margin-top: 24px;
				margin-left: 0;
				width: 100%;
				&:first-child {
					margin-top: 25px;
					&.item-first-day-of-month {
						margin-top: 100px;
					}
				}
				&.item-first-day-of-month {
					margin-top: 80px;
				}
				&.item-left {
					padding-right: 15px;
				}
			}
			.k-pagination {
				padding-left: 0;
				.read-more {
					margin-left: 0;
				}
			}
			.icon-down {
				left: 50%;
				height: 40px;
				width: 40px;
				line-height: 40px;
				margin-left: -20px;
				top: -20px;
			}
			.item-time {
				width: 110px;
				left: 50%;
				top: -56px;
				margin-left: -55px;
				margin-top: 0;
				font-size: 15px;
				line-height: 26px;
				border-width: 3px;
			}
			.item-line {
				display: none;
			}
			.line {
				left: 50%;
			}
		}
	}
	.k-map-contact-form {
		.row {
			padding-top: 310px;
		}
		.col-md-6 {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.k-widget-info-2 {
		strong {
			display: inline-block;
		}
	}

	.k-widget-portfolio {
		.item {
			&.item-lg, &.item-sm {
				width: 100%;
			}
		}
		.read-more {
			display: none;
		}
		.hover_div {
			margin: 0;
		}
		.item {
			padding: 0;
			margin-bottom: 10px;
		}
	}

	.k-widget-post-two-size-thumb {
		p.text-center {
			display: none;
		}
	}

	.k-widget-post-single-carousel-2 {
		padding-bottom: 0;
		.widget-title {
			margin-left: 0;
			padding-left: 0;
			&:before {
				left: 0;
			}
		}
		.widget-content {
			padding-top: 0;
			background: none;
			&:before,
			&:after {
				display: none;
			}
		}
		.owl-carousel {
			margin: 0;
		}
	}

	.k-widget-number {
		.list-item {
			border-bottom: none;
		}
		.item {
			width: 50%;
			border-bottom: 1px solid $color-border;
		}
	}

	.k-widget-progress {
		.col-md-6:first-child {
			margin-bottom: 20px;
		}
	}

	.k-widget-portfolio-single-img {
		.item-content {
			padding-top: 45px;
		}
		.itme-title {
			font-size: 24px;
			line-height: 26px;
			margin-bottom: 10px;
		}
		.item-thumb {
			margin-top: 0;
		}
		.read-more {
			display: none;
		}
	}

	.k-widget-info-2 {
		.overlay {
			padding: 10px 0;
		}
		.item {
			padding: 10px 0;
		}
	}

	/* single page */
	.box-user-post{
		padding: 20px;
		.box-user-avatar {
			position: relative;
			left: auto;
			right: auto;
			margin-bottom: 15px;
		}
	}
	/* comments */
	.comment-author {
		.avatar {
			float: none;
			margin-bottom: 15px;
		}
	}
	.comment-list {
		.reply, .comment-content {
			padding-left: 0;
		}
		.children {
			margin-left: 0;
		}
	}
	/* shop list*/
	.k-product-result-count {
		float: left;
	}
	/* shop detail */

	.entry-product  {
		#sync1 {
			margin-bottom: 0;
		}
		.wrap-sync2 {
			display: none;
		}
		#product-summary {
			margin-bottom: 10px;
		}
	}
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
	%widget-header-mobile {
		.widget-header  {
			margin-bottom: 20px;
			.icon {
				margin-bottom: 15px;
			}
		}
		.widget-title {
			font-size: 24px;
		}
		.widget-des {
			display: none;
		}
	}
	%k-widget-header-mobile {
		margin: 50px 0;
		&.has-header {
			margin-top: 50px;
			&.has-icon-header {
				margin-top: 50px;
				@extend %widget-header-mobile;
			}
		}
		&.has-background {
			margin: 0;
			.overlay {
				padding: 50px 0;
			}

			&.has-header {
				.overlay {
					padding-top: 45px;
				}

				&.has-icon-header {
					.overlay {
						padding-top: 50px;
					}

				}
			}
		}
	}
	.k-widget {
		@extend %k-widget-header-mobile;
	}

	/*header */
	.header-page {
		&.header-page-1 {
			.mobile-menu {
				font-size: 28px;
				color: #fff;
			}
		}

		&.header-page-2 {
			.top-logo {
				display: none;
			}
			.info-c,
			.list-social {
				display: none;
			}
			.mobile-menu {
				font-size: 28px;
				position: absolute;
				right: 60px;
				top: 8px;
			}
			.m-logo {
				display: inline-block;
				line-height: 40px;
				img {
					display: inline-block;
					max-height: 45px;
					width: auto;
				}
			}
			.bottom-header-page {
				display: none;
			}
		}

		&.header-page-3 {
			border-bottom: 1px solid #575757;
			.top-logo {
				display: none;
			}
			.info-c,
			.list-social {
				display: none;
			}
			.mobile-menu {
				font-size: 28px;
				position: absolute;
				right: 45px;
				top: 11px;
				color: #fff !important;
			}
			.m-logo {
				display: inline-block;
				line-height: 48px;
				img {
					display: inline-block;
					max-height: 50px;
					width: auto;
				}
			}
			.inner-top-header-page {
				border-bottom: none;
				padding: 0;
			}
			.bottom-header-page {
				display: none;
			}
		}

		&.header-page-4 {
			.top-logo {
				display: none;
			}
			.top-header-page {
				border-top: none;
				padding: 0;
			}
			.info-ad,
			.list-social {
				display: none;
			}
			.mobile-menu {
				font-size: 28px;
				position: absolute;
				right: 45px;
				top: 10px;
				color: #fff !important;
			}
			.m-logo {
				display: inline-block;
				line-height: 48px;
				img {
					display: inline-block;
					max-height: 50px;
					width: auto;
				}
			}
			.sb-search {
				top: 12px;
			}
			.middle-header-page {
				display: none;
			}
			.bottom-header-page {
				display: none;
			}
		}

		&.header-page-5 {
			.top-logo {
				display: none;
			}
			.info-c,
			.list-social {
				display: none;
			}
			.mobile-menu {
				font-size: 28px;
				position: absolute;
				right: 45px;
				top: 8px;
				color: #fff !important;
			}
			.m-logo {
				display: inline-block;
				line-height: 40px;
				img {
					display: inline-block;
					max-height: 45px;
					width: auto;
				}
			}
			.inner-top-header-page {
				padding: 0;
			}
			.bottom-header-page {
				display: none;
			}
		}
	}

	/* heading page */
	.heading-page {
		.overlay {
			padding: 10px 0;
		}
		.heading-page-title {
			display: none;
		}
		.kopa-breadcrumb {
			float: none;
		}
	}

	.sf-mega-section {
		p {
			display: none;
		}
		&.sf-mega-section-1 {
			padding-right: 15px;
		}
	}
	/* search box  */

	.sb-search-open {
		.sb-search-input {
			background: #fff !important;
		}
	}

	/* widget */
	.k-widget-full-width-single-carousel  {
		height: auto;
		p {
			width: 100%;
			padding: 0 15px;
			font-size: $font-size-base;
		}
		.item-title {
			font-size: 24px;
			margin-top: 85px;
		}
		.owl-controls {
			display: none !important;
		}
		.read-more {
			display: none;
		}
	}

	.k-widget-portfolio-circle-img {
		.widget-content {
			margin-top: 30px;
			background: none;
		}
		.item {
			margin: 0 auto 25px;
			float: none;
			&:last-child {
				margin-bottom:0;
			}
		}
		.item-title {
			margin-top: 10px;
		}
		.item-size-2,
		.item-size-4  {
			.item-title {
				margin-top: 10px;
			}
		}
	}

	.k-widget-portfolio-icon {
		.widget-content {
			.row {
				> div {
					margin-bottom: 25px;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
		.icon-box.style-2 {
			p {
				margin-bottom: 2px;
			}
			.divider {
				margin: 5px 0;
			}
			.icon {
				height: 50px;
				width: 50px;
				line-height: 48px;
			}
		}
	}

	.k-widget-info-2 {
		.overlay {
			font-size: 16px;
		}
		.icon {
			margin-right: 20px;
			height: 55px;
			width: 55px;
			line-height: 55px;
			font-size: 24px;
		}
		.item-envelope {
			float: left;
			width: 100%;
		}
	}

	.k-widget-text {
		.widget-title {
			font-size: 24px;
		}
		&.bg-img {
			.overlay {
				padding: 45px 0 50px;
			}
		}
	}

	.widget-icon-boxes {
		margin: 40px 0 10px;
		.widget-title {
			font-size: 25px;
			line-height: 30px;
		}
		.col-xs-12 {
			&:first-child {
				margin-bottom: 15px;
			}
		}
		.icon-box {
			&.style-1 {
				margin-bottom: 20px;
				.icon {
					margin-bottom: 15px;
				}
			}
		}
	}

	.k-feature-products {
		margin-bottom: 0 !important;
		.item {
			margin-bottom: 50px;
		}
	}

	.k-product-buttons {
		a {
			height: 50px;
			width: 50px;
			line-height: 50px;
		}
	}

	.k-widget-testi {
		.overlay {
			padding: 50px 0;
		}
		&.style-2 {
			.overlay {
				padding: 25px 0px 55px;
			}
			.widget-content {
				padding: 30px 0;
			}
			.widget-title {
				margin-bottom: 35px;
			}
			.owl-controls {
				bottom: -60px;
			}
		}
	}

	.k-widget-portfolio {
		.hover_div a > div {
			display: none !important;
		}
	}

	.k-widget-post-two-size-thumb {
		&.has-background {
			.overlay {
				padding-bottom: 25px;
			}
		}
		.item-thumb {
			margin-bottom: 15px;
		}
		.item {
			margin-bottom: 25px;
		}
		.item-title {
			margin-bottom: 5px;
			font-size: 20px;
		}
	}

	.k-widget-post-single-carousel-2 {
		.owl-carousel {
			.owl-controls {
				.owl-buttons {
					right: auto;
					bottom: auto;
					top: 50%;
					margin-top: -30px;
					width: 100%;
					div {
						position: absolute;
						top: 0;
						height: 40px;
						width: 40px;
						line-height: 40px;
					}
					.owl-prev {
						left: 0;
					}
					.owl-next {
						right: 0;
					}
				}
			}

		}
	}

	.k-widget-portfolio-filter {
		margin-top: 25px;
		margin-bottom: 30px;
		.widget-title {
			font-size: 24px;
		}
		.widget-des {
			display: none;
		}
		.masonry-filter {
			margin-bottom: 0;
			a {
				padding: 0 10px;
				line-height: 30px;
				margin-bottom: 10px;
			}
		}
		.item {
			padding-bottom: 15px;
		}
		.item-thumb {
			margin-bottom: 15px;
		}
	}

	.k-widget-text {
		padding: 45px 0;
	}

	.k-widget-post-carousel {
		.item-time {
			display: none;
		}
		.item-thumb {
			margin-bottom: 10px;
		}
		.item-title {
			margin-top: 0;
			margin-bottom: 10px;
		}
		.owl-controls {
			display: none !important;
		}
	}

	.k-widget-newsletter-2 {
		.overlay {
			padding: 25px 0px 30px;
		}
		.widget-des,
		.widget-des-2 {
			display: none;
		}
		.widget-title {
			font-size: 24px;
			margin-bottom: 15px;
		}
		.form-control {
			height: 50px;
		}
		button {
			top: 10px;
			right: 10px;
		}
	}

	.k-map-contact-form {
		.col-md-6 {
			padding-top: 25px;
			padding-bottom: 30px;
		}
		.widget-title {
			font-size: 24px;
		}
		.widget-des {
			display: none;
		}
		.form-control {
			height: 40px;
		}
		.form-message {
			margin-bottom: 20px;
		}
	}

	.k-widget-related-product {
		.k-item-product-thumb {
			margin-bottom: 15px;
		}
	}

	.sidebar  {
		.widget {
			margin-bottom: 20px;
		}

		.widget-title {
			margin-bottom: 20px;
		}

	}

	.k-widget_search {
		.form-control {
			height: 40px;
		}
		button {
			height: 40px;
			line-height: 40px;
		}
	}

	.k-widget-text.bg-img.has-des {
		.overlay {
			padding: 25px 0 20px;
		}
		.widget-title {
			font-size: 24px;
		}
		.widget-des {
			font-size: $font-size-base;
			line-height: 24px;
			margin-bottom: 10px;
		}
		.read-more.read-more-border {
			padding: 5px 10px;
			margin-bottom: 10px;
			line-height: 24px;
		}
	}
	.k-widget-text.bg-img .widget-title {
		font-size: 25px;
	}

	/* footer */
	.widget-area-2, .widget-area-3 {
		padding-top: 45px;
		padding-bottom: 25px;
	}
	.widget-area-2 {
		.widget-title {
			margin-bottom: 15px;
		}
	}
	.top-footer-page .widget-title,
	.middle-footer-page .widget-title {
		font-size: 22px;
	}
	.k-widget-newsletter  {
		form {
			margin-bottom: 15px;
		}
		.widget-title {
			margin-bottom: 15px;
		}
		input {
			height: 40px;
		}

		input[type="submit"] {
			line-height: 40px;
			padding: 0 10px;
		}
	}
	.middle-footer-page {
		padding-bottom: 0;
		padding-top: 45px;
		.widget {
			margin-bottom: 45px;
		}
		.k-widget-info {
			margin-bottom: 35px;
		}
		.widget-title {
			margin-bottom: 15px;
		}

	}
	.bottom-footer-page {
		padding: 15px 0;
	}

	/* blog page */
	.sidebar {
		margin-top: 30px;
		.widget-title {
			font-size: 20px;
		}
	}
	.list-blog-post .item-title {
		font-size: 25px;
	}
	.k-timeline {
		.icon-down {
			left: 50%;
			font-size: 13px;
		}
		&.two-col {
			.item {
				margin-top: 25px;
			}
		}
		&.one-col {
			.item {
				margin-top: 24px;
				margin-left: 0;
				&:first-child {
					margin-top: 25px;
					&.item-first-day-of-month {
						margin-top: 100px;
					}
				}
				&.item-first-day-of-month {
					margin-top: 80px;
				}
			}
			.k-pagination {
				padding-left: 0;
				.read-more {
					margin-left: 0;
				}
			}
			.icon-down {
				left: 50%;
				height: 40px;
				width: 40px;
				line-height: 40px;
				margin-left: -20px;
				top: -20px;
			}
			.item-time {
				width: 110px;
				left: 50%;
				top: -56px;
				margin-left: -55px;
				font-size: 15px;
				line-height: 26px;
				border-width: 3px;
			}
			.item-line {
				display: none;
			}
			.line {
				left: 50%;
			}
		}
	}

	.list-blog-post {
		&.list-blog-post-timeline {
			.item {
				.item-inner {
					padding-bottom: 20px;
				}
			}
			.read-more {
				display: none;
			}
			.item-text-content {
				margin-bottom: 0;
			}
			.item-thumb {
				margin-bottom: 15px;
			}
			.item-metadata-2,
			.item-metadata-3 {
				padding-bottom: 10px;
			}
		}
		.item-metadata-2 {
			margin-bottom: 10px;
		}

		.k-link {
			p {
				font-size: 18px;
				line-height: 24px;
				&:after {
					display: none;
				}
			}
		}

	}

	.k-widget-post-single-carousel  {
		.overlay {
			padding: 15px 0 40px;
		}
		.widget-title {
			font-size: 30px;
		}
		.item-title {
			font-size: 18px;
			line-height: 24px;
			margin: 0;
		}
	}

	/* contact page */

	.contact-page {
		.k-widget-contact-form {
			margin-bottom: 50px;
		}
	}

	/* single page */
	.entry-post,
	.entry-page {
		.entry-title {
			font-size: 25px;
			line-height: 30px;
		}
	}
	.k-post-navigation {
		display: none;
	}

	.heading-page {
		margin-bottom: 30px;
	}

	.box-tags-post {
		margin-bottom: 30px;
	}

	.box-share-post {
		margin-bottom: 30px;
		padding: 15px 0;
	}

	.box-user-post {
		margin-bottom: 20px;
	}
	.k-related-post {
		margin-bottom: 20px;
		padding-bottom: 0;
		.item-thumb{
			margin-bottom: 10px;
		}
	}

	/* comments */
	.comments-area .comments-title,
	.comments-area .comment-reply-title {
		margin-bottom: 15px;
	}
	.comments-area {
		.k-pagination {
			margin: 15px 0;
		}
	}

	.comment-respond {
		padding-top: 20px;
	}

	body.single-page {
		.footer-page {
			margin-top: 0;
		}
	}

	/* metadata time */
	.k-widget-post-two-size-thumb .item-metadata > span,
	.list-blog-post .item-metadata > span,
	.entry-post .item-metadata > span {
		width: 50px;
		height: 50px;
		padding-top: 5px;
		.item-metadata-time-day,
		.item-metadata-comments-count {
			font-size: 20px;
			margin-bottom: 1px;
		}
	}

	/* pagination */
	.k-pagination {
		margin: 25px 0 0;
	}

	/* shop list */
	.k-product-order {
		.k-orderby {
			height: 36px;
			font-size: 18px;
		}
	}
	.k-product-result-count {
		padding: 5px 0;
	}
	.k-list-product {
		margin-top: 30px;
		.k-item-product {
			margin-bottom: 30px;
		}
		.k-item-product-thumb {
			margin-bottom: 15px;
		}
	}

	/* shop detail */
	.zoomContainerm
	#product-zoom {
		display: none;
	}

	.entry-product {
		padding-bottom: 20px;
		.entry-thumb {
			margin-bottom: 15px;
		}
		p.price,
		.k-product-rating {
			margin-bottom: 15px;
		}
		.product-info {
			margin-bottom: 15px;
		}
		.form-add-to-cart {
			margin-bottom: 25px;
		}
		.k-entry-summary {
			margin-bottom: 25px;
		}
	}

	/* service page */
	.service-tabs {
		margin-bottom: 30px;
		.box {
			margin-bottom: 25px;
		}
		.box-image {
			margin-bottom: 15px;
		}
		.box-title {
			margin-bottom: 20px;
			padding-bottom: 10px;
		}
		.k-list {
			margin-top: 15px;
		}
	}
	.k-list {
		li {
			margin-top: 15px;
		}
	}
	/* single portfolio */

	.entry-portfolio {
		.entry-title {
			margin-bottom: 10px;
		}
		.entry-content {
			margin-bottom: 10px;
		}
		.box-meta {
			margin-bottom: 15px;
		}
		.read-more {
			margin-bottom: 15px;
		}
		&.entry-portfolio-2 {
			.entry-thumb {
				margin-bottom: 15px;
			}
			.entry-content {
				margin-bottom: 15px;
			}
			.box-meta {
				margin-bottom: 15px;
			}
		}
	}

	/* footer */
	.top-footer-page {
		&:before {
			display: none;
		}
		.container {
			.row {
				&:before {
					display: none;
				}
			}
		}
	}
	.widget-area-3 {
		padding-left: 15px;
	}
	body.single-page , body.single-portfolio{
		.footer-page {
			margin-top: 30px;
		}
	}
}
@media screen and (min-width: 320px) and (max-width: 1279px) {
	.k-widget-logo {
		.owl-carousel .owl-controls .owl-buttons {
			div.owl-prev {
				left: 0;
			}
			div.owl-next {
				right: 0;
			}
		}
	}
}

@media screen and (min-width: 480px) and (max-width: 767px){

	/* widget */
	.k-widget-info-2 {
		.item-phone,
		.item-envelope {
			margin-left: 75px;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
	.container {
		width: 100%;
	}
	/* header */
	.header-page {
		&.header-page-1 {
			.mobile-menu {
				position: relative;
				float: left;
				line-height: 55px;
				height: 55px;
				right: auto;
				top: auto;
				margin-top: 0;
			}
		}
	}

	/* mobile menu */

	.sf-mega-section {
		&.sf-mega-section-1 {
			margin-bottom: 15px;
		}
	}
	/* widget */

	.k-widget-portfolio-icon {
		.icon-box {
			&.style-2 {
				text-align: left;
				padding-left: 65px;
				position: relative;
				.icon {
					position: absolute;
					left: 0;
					top: 3px;
				}
			}
		}
	}
	.k-widget-post-carousel {
		.row {
			margin-left: -15px !important;
			margin-right: -15px !important;
			> div {
				padding-left: 15px !important;
				padding-right: 15px !important;
			}
		}
		.item {
			text-align: left;
			padding: 0 !important;
			float: none !important;
		}
		.item-thumb {
			float: left !important;
		}
		.item-content {
			float: right !important;
		}
		.item-title {
			&:before {
				left: 0 !important;
				margin-left: 0 !important;
			}
		}
	}

	.k-widget-post-two-size-thumb {
		p.text-center {
			display: block;
			position: relative;
			right: auto;
			bottom: auto;
		}
	}

	.k-widget-portfolio-single-img {
		.item-content {
			padding-top: 45px;
			padding-bottom: 20px;
		}
		.read-more {
			display: none;
		}
	}
	.k-timeline.two-col  {
		.item-time {
			border: none;
			width: 110px;
			line-height: 26px;
			font-size: 16px;
		}
		.item-left {
			.item-time {
				right: -55px;
			}
		}
		.item-right {
			.item-time {
				left: -55px;
			}
		}
	}
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
	.k-widget-portfolio-circle-img {
		.item-size-1 {
			margin-right: 35px;
		}
		.item-size-2 {
			margin-right: 35px;
		}
		.item-size-3 {
			margin-right: 40px;
		}
	}
	.k-timeline {
		&.two-col {
			.item-time {
				width: 130px;
				font-size: 16px;
				line-height: 26px;
			}
			.item-left {
				.item-time {
					right: -65px;
				}
			}
			.item-right {
				.item-time {
					left: -65px;
				}
			}
		}
	}
}

/*
* 9.0 Drupal overrides
*/
@import 'drupal'; // Drupal overrides and fixins functions.
