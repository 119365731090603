//
// Fixins variables and functions.
// --------------------------------------------------

// Bootstrap library.
@import 'bootstrap';

// Media query variables.
$phones: "(max-width: #{$screen-xs-max})";

@mixin fixins-border-radius($radius) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin fixins-box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin fixins-flex-container() {
  // Under construction...
}

@mixin fixins-form-control() {
  display: block;
  //  width: 100%;

  // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  height: $input-height-base;
  padding: $padding-base-vertical $padding-base-horizontal;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $input-color;

  // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  background: $input-bg none;
  border: 1px solid $input-border;
  border-radius: $input-border-radius;
  @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075));
  @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus;

  // Placeholder
  @include placeholder;

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &[disabled], &[readonly], fieldset[disabled] & {
    cursor: not-allowed;
    background-color: $input-bg-disabled;
    opacity: 1; // iOS fix for unreadable disabled content
  }

  &[disabled], fieldset[disabled] & {
    cursor: $cursor-disabled;
  }

  // Reset height for `textarea`s
  textarea {
    & {
      height: auto;
    }
  }
}

@mixin fixins-opacity($opacity) {
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  //  -ms-filter: e(% ("@{fp_alpha}(Opacity='%d')", $opacity-ie));
  //  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=$opacity-ie);

  -khtml-opacity: $opacity;
  -moz-opacity: $opacity;
  opacity: $opacity;
}

@mixin fixins-placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}
