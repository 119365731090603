//
// Base-theme elements.
// --------------------------------------------------

html {
  body {
    &.admin-menu {
      margin-top: 28px !important;
    }
    &.adminimal-menu {
      margin-top: 29px !important;
    }
  }
}

body.adminimal-menu #admin-menu #admin-menu-wrapper .dropdown li li.expandable a {
  padding-right: 0;
}

a {
  &:hover, &:focus {
    text-decoration: none;
  }
}

button {
  &:hover,
  &:focus,
  &.focus {
    outline: none;
    outline-offset: 0;
  }
}

.button {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: $btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $btn-border-radius-base);
  @include user-select(none);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      // @include tab-focus;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: $btn-default-color;
    text-decoration: none;
    outline: none;
    outline-offset: 0;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    @include opacity(.65);
    @include box-shadow(none);
  }

  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
  @include fixins-border-radius(0px);

  // background: #0c86c6;
  // color: #fff;
  // border: none;
  line-height: 46px;
  padding: 0 30px;
  font-family: "Titillium Web", sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;

  margin-right: 5px;
}

a.button {
  &.disabled,
  fieldset[disabled] & {
    pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
  }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  // line-height: 1.3;
}

h2, .h2 {
  &.element-invisible {
    margin: 0;
  }
}

p {
  font-family: inherit;
  // font-weight: normal;
}

div.contextual-links-wrapper {
  font-family: "Lucida Grande", Verdana, sans-serif;
  font-size: $font-size-small;
}

input[type="checkbox"] {
  float: left;
}
