//
// Main Drupal style file.
// --------------------------------------------------
// @import 'base'; /* Moving to style.scss file. */
@import 'layout';
@import 'components';

//
// Global fix
// --------------------------------------------------
// Bootstrap components
.alert {
  @include fixins-border-radius(0);
  margin-top: 10px;
}

.btn {
  @include fixins-border-radius(0);
}

.close {
  font-family: $font-family-sans-serif;
}

.form-submit {
  // background: $color-pri;
  // color: #fff;
  border: none;
  line-height: 46px;
  padding: 0 30px;
  font-family: $font-heading;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.form-submit:hover {
  // background: $color-black;
  // border-color: $color-black;
}

.glyphicon {
  top: 2px;
}

input[type="radio"],
input[type="checkbox"] {
  margin-top: 4px !important;
}

.icon-sticky {
  &:before,
  &:after {
    margin-right: 0;
    margin-left: 0;
  }
}

.social-links {
  .field-widget-socialfield-widget & {
    .icon {
      font-size: 20px;
      padding: 8px;
      position: absolute;
    }
  }
  .service-rss .icon {
    background: #ed7000;
  }
}

body.landing-page.no-sidebars .main-section.col-xs-12 {
  padding-left: 0px;
  padding-right: 0px;
}

.sidebar {
  .block {
    margin-bottom: 40px;
    .widget-title {
      margin-top: 0;
    }
  }
}

.tabs--primary.nav-tabs {
  margin-top: 5px;
}

// Search box
.sb-search {
  .input-group {
    display: block;
  }
  .sb-search-input {
    border: none;
    padding-right: 24px;
    text-indent: -9999px;
    .header-page-1 & {
      padding-right: 67px;
    }
    .header-page-2 & {
      padding-right: 57px;
    }
  }
  &.sb-search-open .sb-search-input {
    text-indent: 0;
  }
  .input-group-btn {
    display: none;
    visibility: hidden;
  }
}

// Form
.node-form {
  margin-bottom: 20px;
  .table-responsive {
    overflow-x: visible;
  }
}

// Panels
#panels-dnd-main div.panel-region {
  @include fixins-border-radius(0px);
}

.panels-add-content-modal {
  background: none;
}

.content-type-button .img-responsive {
  display: inline;
  vertical-align: baseline;
}

html.js #panels-dnd-main div.ctools-dropdown div.ctools-dropdown-container hr,
html.js div.panels-display-links div.ctools-dropdown-container hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.option-text-aligner {
  padding-left: 25px;
}

//
// 2.0 Component
// --------------------------------------------------
// Tabs
.k-tabs {
  .nav-tabs {
    margin-bottom: 0;
  }
}

// Icon box
.paragraphs-item-icon-boxes.icon-box.style-1 {
  padding-top: 15px;
}

.icon-box.style-2 {
  .icon {
    background: transparent;
    margin-left: auto;
    margin-right: auto;
  }
  .icon-active {
    border-color: $color-pri;
    color: $color-pri;
  }
}

.info-c {
  li span {
    display: none;
    visibility: hidden;
    .header-page-1 &,
    .header-page-4 & {
      display: block;
      visibility: visible;
    }
  }
}

// Socials
.list-social-block {
  float: left;
  .header-page-1 & {
    float: none;
    margin-right: 55px;
    position: absolute;
    top: 0;
    right: 0;
  }
  .header-page-2 & {
    float: right;
    margin-right: 45px;
  }
  .list-social {
    margin-right: 0 !important;
  }
}

.list-social-2 a.fa-linkedin {
  background: #0e76a8;
}

.list-social-2 a.fa-tumblr-square {
  background: #36465d;
}

.list-social-2 a.fa-google-plus {
  background: #dc4a38;
}

// Pagination
ul.page-numbers .pager a {
  display: block;
}

ul.page-numbers .first,
ul.page-numbers .last {
  font-weight: 600;
  color: $color-black;
  text-transform: uppercase;
  line-height: 30px;
  width: auto;
}

ul.page-numbers .first i,
ul.page-numbers .last i {
  font-size: 16px;
}

ul.page-numbers .first i {
  margin-right: 6px;
}

ul.page-numbers .last i {
  margin-left: 6px;
}

ul.page-numbers li:hover * {
  color: $color-pri;
}

.k-widget-portfolio-filter {
  margin-top: 0;
  ul.pager--infinite-scroll {
    display: none;
    visibility: hidden;
  }
}

// Widget
.k-widget-demo {
  &:first-of-type {
    margin-top: 0;
  }
  &.k-widget-progress .widget-title {
    font-size: 20px;
    margin-bottom: 40px;
  }
}

.widget-area-2 .widget-title {
  font-size: 24px;
}

.middle-footer-page .widget-title {
  color: #FFF;
}

.widget_text.widget-area-2 {
  .textwidget {
    font-size: 18px;
    small {
      font-size: 15px;
      color: #777;
    }
  }
}

.widget-area-3 {
  margin-left: 1px;
  margin-right: -1px;
}

// Widget newsletter
.k-widget-newsletter {
  form {
    min-height: 60px;
  }
  .form-item-mail {
    margin-right: 145px;
    label {
      display: none;
      visibility: hidden;
    }
    & ~ button[type="submit"] {
      border-left: 3px solid #252932;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  input[type="text"] {
    width: 100%;
  }
  button[type="submit"] {
    padding: 0 30px;
    line-height: 60px;
    color: #fff;
    background: $color-pri;
    font-family: $font-heading;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    border-radius: 0;
    letter-spacing: 2px;
    &:hover {
      background: #fff;
      color: $color-pri;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .k-widget-newsletter .form-item {
    margin-right: 105px;
  }
  .k-widget-newsletter button[type="submit"] {
    line-height: 40px;
    padding: 0 10px;
  }
}

//
// 3.0 Widget
// --------------------------------------------------
.k-widget-post-single-carousel {
  .widget-title {
    margin-top: 0;
  }
  .item-title {
    p {
      color: $color-black;
    }
  }
}

.k-widget-post-single-carousel-2 {
  body.landing-page & {
    margin-top: 0;
    padding-bottom: 60px;
  }
  .owl-carousel {
    margin-top: -104px;
  }
}

.k-widget-contact-form {
  .form-textarea-wrapper {
    margin-bottom: 30px;
  }
  textarea.form-control {
    @include fixins-border-radius(0);
    margin-bottom: 0;
  }
}

.k-map-contact-form {
  form {
    .grippie {
      display: none;
      visibility: hidden;
    }
  }
  .form-actions {
    margin-top: 35px;
    text-align: right;
  }
}

.k-widget-logo {
  .item {
    img.img-responsive {
      display: inline;
    }
  }
}

.k-widget-testi {
  .field-name-field-testimonial-client {
    font-weight: 700;
    color: #fff;
  }
}

.k-widget-newsletter-2 {
  .form-group {
    margin-bottom: 0;
  }
  .control-label {
    display: none;
    visibility: hidden;
  }
  .form-control {
    width: 100%;
  }
  button.form-submit {
    padding: 0 13px;
    top: 7px;
    right: 7px;
    &:focus,
    &.focus,
    &:hover {
      background-color: transparent;
      border-color: transparent;
    }
    i {
      color: $color-pri;
      &:focus,
      &.focus,
      &:hover {
        color: $color-pri;
      }
    }
  }
}

.tp-revslider-slidesli {
  * {
    font-family: $font-heading !important;
  }
  .read-more.read-more-border {
    border-color: #8f8f8f;
    &:hover {
      border-color: $color-pri;
      color: $color-pri !important;
    }
    &:after {
      @extend .fa;
      @extend .fa-angle-right:before;
      margin-left: 5px;
      font-size: 14px;
    }
  }
}

.k-widget-logo {
  & + .k-widget-post-two-size-thumb {
    margin-top: 0 !important;
  }
}

.k-widget-full-width-single-carousel {
  .entity-bean.bean-carousel {
    height: 100%;
    .content {
      height: 100%;
    }
    .field {
      height: 100%;
    }
  }
}

// Heading page
.region-navigation {
  .block-tb-megamenu.contextual-links-region {
    position: initial;
  }
}

.kopa-breadcrumb {
  .breadcrumb {
    @include fixins-border-radius(0);
    background: transparent;
    margin: 0;
    padding: 0;
    > .active {
      color: #fff;
    }
    > li + li:before {
      content: "\f105";
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

.k-widget-portfolio {
  .widget-content {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .hover_div {
    a {
      img {
        @include scale(1);
      }
      &:hover {
				img {
					@include scale(1.1);
				}
      }
    }
  }
}

//
// 4.0 Header
// --------------------------------------------------
// Superfish menu
.sf-menu {
  & > li {
    position: static;
    & ul,
    & .sf-mega {
      @include fixins-opacity(1);
    }
  }
  .mega-dropdown-menu {
    @include fixins-border-radius(0);
    background: #FFF;
    border: none;
    left: auto;
    margin: 0;
    padding: 0;
    &.sf-mega {
      left: 0;
      padding: 30px 30px 25px;
    }
    .header-page.header-page-2 & {
      margin-top: 3px;
    }
  }
  ul.tb-megamenu-subnav {
    background: #FFF;
    padding: 10px 5px;
    width: 220px;
  }
  li.tb-megamenu-item {
    background: none;
    list-style: none;
    & > a {
      background: none;
      border-top: 1px solid #ddd;
      color: $color-black;
      line-height: 24px;
      padding: 13px 15px;
      &:hover {
        color: $color-pri;
        .header-page.header-page-2 &.sf-with-ul:after {
          color: $color-pri;
        }
      }
    }
    &:first-child > a {
      border-top-color: transparent;
    }
    &.level-1 > a {
      &:hover {
        color: #FFF;
        .header-page.header-page-2 & {
          color: $color-pri;
        }
      }
      & > i {
        display: none;
        visibility: hidden;
        .header-page.header-page-2 & {
          display: block;
          visibility: visible;
        }
      }
    }
    &.dropdown-submenu {
      position: relative;
      & > .mega-dropdown-menu {
        margin-left: 8px;
        position: absolute;
        top: 0;
        left: 100%;
      }
      ul {
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  a {
    &:hover {
      color: $color-pri;
    }
    &.sf-with-ul:hover {
      color: #FFF;
    }
  }
  a.sf-with-ul:after {
    @extend .fa;
    @extend .fa-angle-right:before;
    color: $color-grayer;
    font-size: 16px;
    border: none;
    margin-top: -8px;
  }
}

.node.sf-mega-section {
  padding: 0;
}

//
// 6.0 Pages
// --------------------------------------------------
// Single page
.entry-post,
.entry-page {
  .entry-thumb {
    .field-name-field-blog-media {
      .field-item {
        margin-bottom: 30px;
      }
    }
  }
  .entry-metadata {
    .field-name-field-blog-category {
      display: inline-block;
    }
  }
  .box-user-description {
    p {
      margin: 0 0 10.5px;
    }
  }
}

.box-user-post {
  min-height: 177px;
}

// Single portfolio
.entry-portfolio .box-meta .field-label {
  color: $color-black;
  font-weight: 300;
}

// Blog page
.item-metadata-2-cat {
  display: inline;
}

.list-blog-post {
  .item-metadata-2 {
    .field-name-field-blog-category {
      display: inline-block;
    }
  }
  .field-name-body {
    p {
      color: #818181;
      margin-bottom: 28px;
      line-height: 24px;
    }
  }
  &.list-blog-post-masonry {
    .field-name-body p {
      margin-bottom: 16px;
    }
  }
  &.list-blog-post-timeline .field-name-body p {
    margin-bottom: 25px;
  }
}

.list-blog-post .item:first-child {
  padding-top: 1px;
}

body {
  &.blog-timeline-page {
    .main-section {
      overflow: visible;
    }
    #block-system-main {
      margin-top: -20px;
      padding-top: 20px;
    }
    .list-blog-post {
      overflow: hidden;
    }
  }
}

.k-timeline {
  &.one-col {
    .item {
      right: 0;
    }
  }
}

// Related post
.k-related-post .item-metadata {
  margin-bottom: 20px;
}

// Comment
.comments-area .fn {
  color: #AAA;
}

.comments-area .indented {
  margin: 0 0 0 85px;
}

.comment-list > .comment:first-of-type .comment-body {
  border-top: none;
  padding-top: 0;
}

.comment-list .comment:last-of-type .comment-body {
  border-bottom: none;
}

.comment-meta .user-picture {
  float: left;
  height: 70px;
  margin-right: 15px;
  width: 70px;
}

.comment-respond .form-item label {
  display: none;
  visibility: hidden;
}

.comment-respond .form-type-item label {
  display: inline-block;
  visibility: visible;
}

.comment-respond .form-control {
  width: 100%;
}

.comment-respond .form-submit {
  line-height: 52px;
  font-size: 18px;
  font-weight: 400;
  text-transform: none;
}

// Service page
.node-service {
  .box {
    margin-bottom: 75px;
    .widget-title {
      font-size: 30px;
      margin-bottom: 45px;
      margin-top: 0;
      padding-bottom: 20px;
      font-weight: 400;
      position: relative;
      &:before {
        content: '';
        width: 25px;
        height: 2px;
        background: $color-black;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
  .box-image {
    margin-bottom: 35px;
  }
}

.service-tabs {
  .paragraphs-item-paragraphs-pack-node-list {
    .field-name-pp-title {
      display: none;
      visibility: hidden;
    }
    .field-name-pp-items-node {
      .node {
        header {
          display: none;
          visibility: hidden;
        }
      }
    }
  }
}

//
// 8.0 Responsive
// --------------------------------------------------
@media screen and (min-width: 320px) and (max-width: 1023px) {
  .list-blog-post.list-blog-post-timeline .field-name-body p {
    margin-bottom: 0;
  }
}
