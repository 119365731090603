/* 
 *  Superfist menu
 */
.sf-menu {
	margin: 0;
	padding: 0;
	list-style: none;
	position: relative;
	z-index: 9;
}

.sf-menu > li > ul {
	position: absolute;
	display: none;
	top: 100%;
	left: auto;
	z-index: 99;
	padding-left: 0;
	width: 220px; 
	list-style: none;
	li {
		position: relative;
		ul {
			position: absolute;
			display: none;
			top: 0;
			left: 100%;
			z-index: 99;
			padding-left: 0;
			width: 220px; 
			list-style: none;
		}
	}
}
.sf-menu > li {
	float: left;
}
.sf-menu > li:hover > ul,
.sf-menu > li.sfHover > ul {
	display: block;
}

.sf-menu a {
	display: block;
	position: relative;
}


/*** DEMO SKIN ***/
.sf-menu {
	float: left;
	margin-bottom: 1em;
	width: 100%;
}

.sf-menu a {
	border-left: 1px solid #fff;
	border-top: 1px solid #dFeEFF; /* fallback colour must use full shorthand */
	padding: .75em 1em;
	text-decoration: none;
	zoom: 1; /* IE7 */
}
.sf-menu a {
	color: #13a;
}
.sf-menu li {
	background: #BDD2FF;
	-webkit-transition: background .2s;
	transition: background .2s;
}
.sf-menu ul li {
	background: #AABDE6;
}
.sf-menu ul ul li {
	background: #9AAEDB;
}
.sf-menu li:hover,
.sf-menu li.sfHover {
	background: #CFDEFF;
	/* only transition out, not in */
	-webkit-transition: none;
	transition: none;
}

/*** arrows (for all except IE7) **/
.sf-arrows .sf-with-ul {
	padding-right: 2.5em;
	*padding-right: 1em; /* no CSS arrows for IE7 (lack pseudo-elements) */
}
/* styling for both css and generated arrows */
.sf-arrows .sf-with-ul:after {
	content: '';
	position: absolute;
	top: 50%;
	right: 1em;
	margin-top: -3px;
	height: 0;
	width: 0;
	/* order of following 3 rules important for fallbacks to work */
	border: 5px solid transparent;
	border-top-color: #dFeEFF; /* edit this to suit design (no rgba in IE8) */
}
.sf-arrows > li > .sf-with-ul:focus:after,
.sf-arrows > li:hover > .sf-with-ul:after,
.sf-arrows > .sfHover > .sf-with-ul:after {
	border-top-color: white; /* IE8 fallback colour */
}
/* styling for right-facing arrows */
.sf-arrows ul .sf-with-ul:after {
	margin-top: -5px;
	margin-right: -3px;
	border-color: transparent;
	border-left-color: #dFeEFF; /* edit this to suit design (no rgba in IE8) */
}
.sf-arrows ul li > .sf-with-ul:focus:after,
.sf-arrows ul li:hover > .sf-with-ul:after,
.sf-arrows ul .sfHover > .sf-with-ul:after {
	border-left-color: white;
}


.sf-menu .sf-mega {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99;
}
.sf-menu li:hover > .sf-mega,
.sf-menu li.sfHover > .sf-mega {
  display: block;
}
.sf-menu .sf-mega {
  width: 100%; /* allow long menu items to determine submenu width */
}
/*** mega menu dropdown ***/
.sf-mega {
  background-color: #CFDEFF;
  padding: 1em;
  box-sizing: border-box;
  width: 100%;
}
.sf-mega-section {
  float: left;
  padding: 0 1em 1em 0;
  margin-right: 1em;
  border-right: 1px solid #b4c8f5;
}